import React, { useEffect, useState, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Table, Typography, Spin, Card, Tabs, Collapse } from "antd";
import DashboardBackground from "../components/Dashboard/DashboardBackground";
import { useNavigate } from "react-router-dom";
import TokenExpireModal from "../components/TokenExpireModal";
import { notificationsContext } from "../context/Notifications";
import newAsset from "../api/assetAxios";

import moment from "moment";
import CustomButton from "../components/CustomButton";

const { Title } = Typography;
const AssetViewDetail = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [data, setData] = useState();
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isAlertLoading, setIsAlertLoading] = useState(false);
  const [isNodeLoading, setIsNodeLoading] = useState(false);

  useEffect(() => {
    (async () => fetchData())();
  }, [id]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await newAsset.get(`/asset/${id}`);
      setIsLoading(false);
      setData(res?.data?.data);
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };

  const Asset = (data) => {
    return (
      <>
        <div className="mt-6">
          {isLoading ? (
            <Spin className="flex justify-center items-center" />
          ) : (
            <>
              <div className="flex flex-col gap-3 lg:gap-0 lg:flex-row  lg:justify-between mt-3">
                <Card className="w-auto lg:w-[49%] border-[3px]">
                  <div style={{ width: "100%" }}>
                    <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                      <div className="font-semibold">Locations</div>
                      <div className="flex flex-col">
                        {data?.data?.location}
                      </div>
                    </div>
                    <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                      <div className="font-semibold">Nodes:</div>
                      <div className="flex flex-col">
                        {data?.data?.nodes?.length > 0
                          ? data?.data?.nodes.map((node, index) => (
                              <div key={index}>{node}</div>
                            ))
                          : "Null"}
                      </div>
                    </div>
                    <div className="flex border-b border-gray-100 justify-between align-center h-auto ">
                      <div className="font-semibold">Nodes UIDs:</div>
                      <div className="flex flex-col">
                        {data?.data?.nodes_uids?.length > 0
                          ? data?.data?.nodes_uids.map((node, index) => (
                              <div key={index}>{node}</div>
                            ))
                          : "Null"}
                      </div>
                    </div>
                  </div>
                </Card>
                <Card
                  className="w-auto lg:w-[49%] border-[3px]"
                  // style={{ width: "49%", borderWidth: "3px" }}
                >
                  <div style={{ width: "100%" }}>
                    <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                      <div className="font-semibold">Device Id:</div>
                      <div className="flex flex-col">
                        {data?.data?.device_id}
                      </div>
                    </div>
                    <div className="flex border-b border-gray-100 justify-between align-center h-7 ">
                      <div className="font-semibold">End of support date:</div>
                      <div className="flex flex-col">
                        {data?.data?.end_of_support_date}
                      </div>
                    </div>
                    <div className="flex border-b border-gray-100 justify-between align-center h-7 mt-3">
                      <div className="font-semibold">End of sale date:</div>
                      <div className="flex flex-col">
                        {data?.data?.end_of_sale_date}
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
              <Card className="w-full mt-3 mb-3" style={{ borderWidth: "3px" }}>
                <div className="flex flex-col border-b border-gray-100 justify-between align-center h-auto mb-3">
                  <div className="font-semibold">Properties</div>
                  <div className="w-full flex flex-row justify-between mt-4">
                    {data?.data?.properties &&
                    Object.keys(data?.data?.properties).length > 0 ? (
                      Object.entries(data?.data?.properties).map(
                        ([ip, details]) => (
                          <div
                            className="w-full lg:w-[30%]"
                            // style={{ width: "30%" }}
                            key={ip}
                          >
                            <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                              <div className="font-semibold">IP:</div>
                              <div className="flex flex-col">{ip}</div>
                            </div>
                            <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                              <div className="font-semibold">Type:</div>
                              <div className="flex flex-col">
                                {details["_type.asset-kb"]}
                              </div>
                            </div>
                            <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                              <div className="font-semibold">Vendor:</div>
                              <div className="flex flex-col">
                                {details["_vendor.passive"]}
                              </div>
                            </div>
                            <div className="flex border-b border-gray-100 justify-between align-center h-auto mt-3">
                              <div className="font-semibold">Product Name:</div>
                              <div className="flex flex-col">
                                {details["_product_name.asset-kb"]}
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="flex justify-center items-center mt-5">
                        No properties available.
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </>
          )}
        </div>
      </>
    );
  };

  const Node = ({ nodes }) => {
    return (
      <>
        {isNodeLoading ? (
          <Spin className="flex justify-center items-center mt-5" />
        ) : nodes?.length > 0 ? (
          <Collapse defaultActiveKey={["1"]} accordion>
            {nodes.map((node, index) => (
              <Collapse.Panel header={`Node ${index + 1}`} key={index}>
                <div className="flex flex-col gap-3 lg:flex-row lg:justify-between mt-3">
                  <Card className="w-auto lg:w-[49%] border-[3px]">
                    <div style={{ width: "100%" }}>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">Appliance Hosts:</div>
                        <div className="flex flex-col">
                          {node?.appliance_hosts?.length > 0
                            ? node.appliance_hosts.map((node, index) => (
                                <div key={index}>{node}</div>
                              ))
                            : "Null"}
                        </div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">Protocols:</div>
                        <div className="flex flex-col">
                          {node?.protocols?.length > 0
                            ? node?.protocols.map((node, index) => (
                                <div key={index}>{node}</div>
                              ))
                            : "Null"}
                        </div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">Received Bytes:</div>
                        <div className="flex flex-col">
                          {node?.received_bytes}
                        </div>
                      </div>
                    </div>
                  </Card>
                  <Card className="w-auto lg:w-[49%] border-[3px]">
                    <div style={{ width: "100%" }}>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">
                          First Activity Time:
                        </div>
                        <div className="flex flex-col">
                          {node?.first_activity_time}
                        </div>
                      </div>

                      <div className="flex border-b border-gray-100 justify-between align-center h-7 mt-2">
                        <div className="font-semibold">Last Activity Time:</div>
                        <div className="flex flex-col">
                          {node?.last_activity_time}
                        </div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mt-2">
                        <div className="font-semibold">Sent Bytes:</div>
                        <div className="flex flex-col">{node?.sent_bytes}</div>
                      </div>
                    </div>
                  </Card>
                </div>
                <Card className="mt-3" style={{ borderWidth: "3px" }}>
                  <div className="flex flex-col w-full lg:flex-row lg:justify-between">
                    <div className="w-auto lg:w-[47%] ">
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">Device Id</div>
                        <div className="flex flex-col">{node?.device_id}</div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">Capture Device</div>
                        <div className="flex flex-col">
                          {node?.capture_device}
                        </div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-7 ">
                        <div className="font-semibold">Asset Id</div>
                        <div>{node?.asset_id}</div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-7 mt-3">
                        <div className="font-semibold">Updated At</div>
                        <div>{moment(node?.updatedAt).format("L")}</div>
                      </div>
                    </div>
                    <div className="w-auto lg:w-[47%] ">
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">BPF Filter</div>
                        <div className="flex flex-col">{node?.bpf_filter}</div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mt-3">
                        <div className="font-semibold">UID</div>
                        <div className="flex flex-col">{node?.uid}</div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mt-3">
                        <div className="font-semibold">Created At</div>
                        <div className="flex flex-col">
                          {moment(node?.createdAt).format("L")}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : (
          <div className="flex justify-center items-center mt-5">
            No Node available.
          </div>
        )}
      </>
    );
  };

  const Alert = ({ alerts }) => {
    return (
      <>
        {isAlertLoading ? (
          <Spin className="flex justify-center items-center mt-5" />
        ) : alerts?.length > 0 ? (
          <Collapse defaultActiveKey={["1"]} accordion>
            {alerts.map((alert, index) => (
              <Collapse.Panel header={`Alert ${index + 1}`} key={index}>
                <div className="flex flex-col gap-3 lg:gap-0 lg:flex-row lg:justify-between mt-3">
                  <Card className="w-auto lg:w-[49%] border-[3px]">
                    <div style={{ width: "100%" }}>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">Name:</div>
                        <div className="flex flex-col">
                          {alert?.name ? alert.name : "Null"}
                        </div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">Type Id:</div>
                        <div className="flex flex-col">
                          <div className="flex flex-col">
                            {alert?.type_id ? alert.type_id : "Null"}
                          </div>
                        </div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">Description:</div>
                        <div className="flex flex-col">
                          {alert?.description}
                        </div>
                      </div>
                    </div>
                  </Card>
                  <Card className="w-auto lg:w-[49%] border-[3px]">
                    <div style={{ width: "100%" }}>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">Appliance Host:</div>
                        <div className="flex flex-col">
                          {alert?.appliance_host}
                        </div>
                      </div>

                      <div className="flex border-b border-gray-100 justify-between align-center h-7 mt-2">
                        <div className="font-semibold">Appliance IP:</div>
                        <div className="flex flex-col">
                          {alert?.appliance_ip}
                        </div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mt-2">
                        <div className="font-semibold">Transport Protocol:</div>
                        <div className="flex flex-col">
                          {alert?.transport_protocol}
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
                <Card className="mt-3" style={{ borderWidth: "3px" }}>
                  <div className="flex w-full flex-col lg:flex-row lg:justify-between">
                    <div className="w-auto lg:w-[47%]">
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">Threat Name</div>
                        <div className="flex flex-col">
                          {alert?.threat_name}
                        </div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">Type Name</div>
                        <div className="flex flex-col">
                          {alert?.capture_device}
                        </div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-7 ">
                        <div className="font-semibold">Attack Technique</div>
                        <div>{alert?.mitre_attack_techniques}</div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-7 mt-3">
                        <div className="font-semibold">Attack Tactics</div>
                        <div>{alert?.mitre_attack_tactics}</div>
                      </div>
                    </div>
                    <div style={{ width: "47%" }}>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">BPF Filter</div>
                        <div className="flex flex-col">{alert?.bpf_filter}</div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mt-3">
                        <div className="font-semibold">Status</div>
                        <div className="flex flex-col">{alert?.status}</div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mt-3">
                        <div className="font-semibold">Created At</div>
                        <div className="flex flex-col">
                          {moment(alert?.createdAt).format("L")}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : (
          <div className="flex justify-center items-center mt-5">
            No Alert available.
          </div>
        )}
      </>
    );
  };

  const item = [
    {
      key: "1",
      label: (
        <span style={{ fontWeight: 600, fontSize: "15px" }}>Overview</span>
      ),
      children: <Asset data={data} />,
    },
    {
      key: "2",
      label: <span style={{ fontWeight: 600, fontSize: "15px" }}>Node</span>,
      children: <Node nodes={nodes} />,
    },
    {
      key: "3",
      label: <span style={{ fontWeight: 600, fontSize: "15px" }}>Alert</span>,
      children: <Alert alerts={alerts} />,
    },
  ];

  const onChange = async (key) => {
    if (key === "2") {
      if (data) {
        fetchNodes();
      }
    } else if (key === "3") {
      if (data) {
        fetchAlerts();
      }
    }
  };

  const fetchNodes = async () => {
    setIsNodeLoading(true);
    try {
      const body = JSON.stringify({
        nozomi_node_ids: data?.nodes || [],
      });
      const res = await newAsset.post("/asset/get-nodes-detail", body);
      setNodes(res?.data?.data);
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsTokenExpiredModalOpen(true);
      } else {
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    } finally {
      setIsNodeLoading(false);
    }
  };

  const fetchAlerts = async () => {
    setIsAlertLoading(true);
    try {
      const body = JSON.stringify({
        nozomi_node_ids: data?.nodes || [],
      });
      const res = await newAsset.post("/asset/node/get-alerts-detail", body);
      setAlerts(res?.data?.data);
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsTokenExpiredModalOpen(true);
      } else {
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    } finally {
      setIsAlertLoading(false);
    }
  };

  return (
    <DashboardBackground>
      <div className="h-16 bg-white px-5 flex justify-between items-center">
        <div className="w-32">
          <CustomButton
            type="primary"
            text={"Back"}
            className="w-32"
            onClick={() => navigate(`/dashboard/assets`)}
          />
        </div>
      </div>
      <div className="h-[80vh] m-5 bg-white px-5 pt-5 overflow-scroll overflow-x-hidden">
        <Title level={3}>{data?.name}</Title>
        <Card style={{ borderWidth: "3px" }}>
          <div className="flex w-full flex-col lg:flex-row lg:justify-between">
            <div className="w-auto lg:w-[47%]">
              <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                <div className="font-semibold">IP ({data?.ip?.length})</div>
                <div className="flex flex-col">
                  {data?.ip?.length > 0
                    ? data?.ip.map((ip, index) => <div key={index}>{ip}</div>)
                    : "Null"}
                </div>
              </div>
              <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                <div className="font-semibold">Roles</div>
                <div className="flex flex-col">
                  {data?.roles?.length > 0
                    ? data?.roles.map((role, index) => (
                        <div key={index}>{role}</div>
                      ))
                    : "Null"}
                </div>
              </div>
              <div className="flex border-b border-gray-100 justify-between align-center h-7 ">
                <div className="font-semibold">Vendor</div>
                <div>{data?.vendor}</div>
              </div>
            </div>
            <div style={{ width: "47%" }}>
              <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                <div className="font-semibold">Mac Address</div>
                <div className="flex flex-col">
                  {data?.mac_address?.length > 0
                    ? data?.mac_address.map((mac, index) => (
                        <div key={index}>{mac}</div>
                      ))
                    : "Null"}
                </div>
              </div>
              <div className="flex border-b border-gray-100 justify-between align-center h-auto ">
                <div className="font-semibold">Mac Vendor</div>
                <div className="flex flex-col">
                  {data?.mac_vendor?.length > 0
                    ? data?.mac_vendor.map((vendor, index) => (
                        <div key={index}>{vendor}</div>
                      ))
                    : "Null"}
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Tabs
          className="mt-4"
          defaultActiveKey="1"
          items={item}
          onChange={onChange}
        />
      </div>
      <TokenExpireModal isTokenExpiredModalOpen={isTokenExpiredModalOpen} />
    </DashboardBackground>
  );
};

export default AssetViewDetail;
