import React from "react";
import backgroundImage from "../assets/background.png";
import logoWhite from "../assets/orbitx-logo-white.png";

const Background = ({ children }) => {
  return (
    <div
      className="w-full h-full"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover", // Stretches the image to fill the container
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="pl-3 sm:w-full sm:pt-6 sm:pl-7 sm:h-[10vh] ">
        <img src={logoWhite} className="h-20" />
      </div>
      <div className="flex  min-h-[90vh] justify-center items-center">
        {children}
      </div>
    </div>
  );
};

export default Background;
