import React, { useContext, useEffect, useState } from "react";
import { BellOutlined, DownOutlined } from "@ant-design/icons";
import { Badge, Dropdown, Space } from "antd";
import { notificationsContext } from "../context/Notifications";
import assessmentApi from "../api/assessmentAxios";
import moment from "moment";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const { api: notificationAPI } = useContext(notificationsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => await getNotification())();
  }, []);

  const handleNotification = (assessmentId, notificationId) => {
    // navigate(  `/dashboard/assessment/assessment-detail/${record?.standard_id}?standard=${record.type}&assessment=${assessmentId}&tenant_id=${user?.data?.user?.tenant_id}`)
    readNotification(notificationId);
    navigate(`/dashboard/assessment`);
  };

  const readNotification = async (id) => {
    try {
      setIsLoading(true);
      const res = await assessmentApi.post(
        "/notification/set-read-notification",
        {
          notification_id: id,
        }
      );
      if (res) {
        getNotification();
        setIsLoading(false);
      }
    } catch (error) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };

  const getNotification = async () => {
    try {
      setIsLoading(true);
      const data = await assessmentApi.get("/notification");
      setNotificationData(data?.data?.data?.notifications);
      setIsLoading(false);
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };

  const menuItems =
    notificationData.length > 0
      ? notificationData.map((notification) => ({
          key: notification.doc_id,
          label: (
            <div
              onClick={() =>
                handleNotification(
                  notification?.assessment_id,
                  notification?.doc_id
                )
              }
              className="p-2 hover:bg-gray-100 cursor-pointer flex justify-between items-baseline"
            >
              <div className="w-10/12">
                <p className="text-sm">{notification.title}</p>
                <span className="text-xs text-gray-500">
                  {moment(notification?.created_at).format(
                    "MMMM D, YYYY [at] h:mm A"
                  )}
                </span>
                <p className="text-xs">{notification.content}</p>
              </div>
              {!notification?.is_read && (
                <div className="w-2 h-2 rounded-full bg-green-500" />
              )}
            </div>
          ),
        }))
      : [
          {
            key: "no-notifications",
            label: (
              <div className="p-2 text-center text-gray-500">
                No Notifications Available
              </div>
            ),
          },
        ];

  const items = [...menuItems];

  const unreadNotification = notificationData.reduce((count, notification) => {
    return notification.is_read ? count : count + 1;
  }, 0);
  console.log("notificationNo", items);

  return (
    <Dropdown
      menu={{
        items,
        className: `max-h-[40vh] w-[265px] ${
          notificationData.length > 0 ? "md:w-[400px]" : "md:w-[250px]"
        }   overflow-y-auto overflow-x-hidden`,
      }}
      placement="bottomRight"
      arrow
      // trigger={["click"]}
    >
      <Badge
        count={unreadNotification ?? 0}
        size="default"
        className="cursor-pointer "
      >
        <BellOutlined className="text-white text-2xl" />
      </Badge>
    </Dropdown>
  );
};
export default Notification;
