import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Content from "./Content";
import Assessment from "../../pages/Assessment";
import Asset from "../../pages/Asset";
import AssessmentDetail from "../../pages/AssessmentDetail";
import AssetViewDetail from "../../pages/AssetViewDetail";
import Integration from "../../pages/Integration";
import Remediation from "../../pages/Remediation";
import ProfileDetail from "../../pages/ProfileDetail";
import NozomiConfig from "../../pages/NozomiConfig";
import ScheduleAssessment from "../../pages/ScheduleAssessment";
import RemediationDetail from "../../pages/RemediationDetail";
import CreateCustomAssessment from "../../pages/CreateCustomAssessment";
import CustomAssessment from "../../pages/CustomAssessment";
import CustomAssessmentStandardView from "../CustomAssessmentList/CustomAssessmentStandardView";
import JiraConfig from "../../pages/JiraConfig";

const Dashboard = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  return (
    <div className="flex h-full overflow-auto lg:flex lg:h-screen lg:overflow-hidden ">
      <Sidebar
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        isMobile={isMobile}
        setIsMobile={setIsMobile}
      />
      <div className="flex-1">
        {!isDrawerOpen && (
          <Header setIsDrawerOpen={setIsDrawerOpen} isMobile={isMobile} />
        )}

        <Routes>
          <Route path="/*" element={<Content page="Home" />} />
          <Route path="assets" element={<Asset />} />
          <Route path="assets/:id" element={<AssetViewDetail />} />
          <Route path="assessment" element={<Assessment />} />
          <Route
            path="assessment/assessment-detail/:id"
            element={<AssessmentDetail />}
          />
          <Route path="remediation" element={<Remediation />} />
          <Route
            path="remediation/remediation-detail/:id"
            element={<RemediationDetail />}
          />
          <Route path="reporting" element={<Content page="Settings" />} />
          <Route path="settings" element={<ProfileDetail />} />
          <Route path="integration" element={<Integration />} />
          {/* <Route path="custom-assessment" element={<CustomAssessment />} />
          <Route
            path="custom-assessment/standard-view"
            element={<CustomAssessmentStandardView />}
          />

          <Route
            path="assessment/custom-assessment"
            element={<CreateCustomAssessment />}
          /> */}
          <Route
            path="assessment/scheduleAssessment"
            element={<ScheduleAssessment />}
          />
          <Route path="integration/configuration" element={<NozomiConfig />} />
          <Route
            path="integration/configuration/jira"
            element={<JiraConfig />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
