import React, { useEffect, useState } from "react";
import { Table, Typography, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import DashboardBackground from "../Dashboard/DashboardBackground";
import CustomButton from "../CustomButton";
import UploadModal from "../UploadModal";
import CustomAssessmentUploadModel from "./CustomAssessmentUploadModel";
const { Title } = Typography;

const CustomAssessmentStandardView = () => {
  const [selectedAsset, setSelectedAsset] = useState();
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const navigate = useNavigate();
  const [data, setData] = useState([
    {
      id: 1,
      name: "Lorem ipsum dolor sit amet consectetur. Donec lorem vulputate gravida in sit congue sit tortor in.",
    },
    {
      id: 2,
      name: "Lorem ipsum dolor sit amet consectetur. Donec lorem vulputate gravida in sit congue sit tortor in.",
    },
  ]);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    (async () => await fetchData())();
  }, [refreshTable, searchText]);

  const fetchData = async (currentPage = 1, pageSize = 10) => {};
  const handleTableChange = async (pagination) => {
    setTableParams({
      pagination,
    });
    await fetchData(pagination.current, pagination.pageSize);
  };

  const columns = [
    {
      title: "Custom Assessment Requirements",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <div className="w-full flex justify-center">
          <div className="w-14">
            <span className="text-sky-500 cursor-pointer">Edit</span>
          </div>
          <div className="w-14">
            <span
              className="text-sky-500 cursor-pointer"
              // onClick={() => handleDelete(record.id)}
            >
              Delete
            </span>
          </div>
        </div>
      ),
      align: "center",
    },
  ];

  return (
    <DashboardBackground>
      <div className="h-16 w-full bg-white px-5 flex justify-between items-center gap-4">
        <div className="flex flex-row gap-4">
          <ArrowLeftOutlined
            onClick={() => navigate(`/dashboard/custom-assessment`)}
          />
          <p style={{ fontSize: "18px", fontWeight: "600" }}>Standard A</p>
        </div>
        <div style={{ display: "flex", gap: "5px" }}>
          {/* <CustomButton
            text={"Upload Image"}
            // type="primary"
            color="default"
            className={"h-11 rounded-lg text-black border-gray-400"}
            variant="outlined"
          /> */}
          <CustomButton
            text={"Upload CSV"}
            type="primary"
            className={"h-11 rounded-lg"}
            onClick={() => setIsUploadModalOpen(true)}
          />
        </div>
      </div>
      <div className="h-[80vh] lg:m-5 bg-white px-5 pt-5 ">
        <div className="mt-6">
          {isLoading ? (
            <Spin className="flex justify-center items-center" />
          ) : (
            <Table
              columns={columns}
              dataSource={data}
              loading={isLoading}
              pagination={{
                ...tableParams.pagination,
                position: ["bottomCenter"],
                showSizeChanger: true,
              }}
              onChange={handleTableChange}
            />
          )}
        </div>
      </div>
      <CustomAssessmentUploadModel
        isUploadModalOpen={isUploadModalOpen}
        setIsUploadModalOpen={setIsUploadModalOpen}
      />
    </DashboardBackground>
  );
};
export default CustomAssessmentStandardView;
