import React, { useContext, useEffect, useState } from "react";
import {
  Divider,
  Form,
  Input,
  Modal,
  Typography,
  Select,
  DatePicker,
  Spin,
} from "antd";
import CustomButton from "../CustomButton";
import { notificationsContext } from "../../context/Notifications";
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";
import api from "../../api/axios";
import assessmentApi from "../../api/assessmentAxios";
import { AuthContext } from "../../context/AuthContext";

const { Title, Text } = Typography;
const { Option } = Select;

const AssignModal = ({
  isAssignModalOpen,
  setIsAssignModalOpen,
  setRefreshTable,
  remediationId,
}) => {
  const [form] = Form.useForm();
  const { Item } = Form;

  const { api: notificationAPI } = useContext(notificationsContext);

  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [assignedToEmail, setAssignedToEmail] = useState("");
  const { user } = useContext(AuthContext);
  const [assignTo, setAssignTo] = useState("");
  const [assignToUsers, setAssignToUsers] = useState([]);
  useEffect(() => {
    (async () => {
      const users = await api.get("/user/list-user");
      setUserList(users?.data?.data);
    })();
  }, []);

  const handleCancel = () => {
    form.resetFields();
    setIsAssignModalOpen(false);
  };

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      const assignDate = new Date(values.assign_date);
      const dueDate = new Date(values.due_date);
      const due_date = dueDate.toISOString().split("T")[0];

      const formData = {
        id: remediationId,
        // assigned_to: assignTo[0],
        // assigned_to_email: assignedToEmail[0],
        assigned_users: assignToUsers,
        assigned_by: user?.data?.user?.name,
        assigned_by_email: user?.data?.user?.email,
        due_date: due_date,
      };
      const response = await assessmentApi.post(
        "/remediation/assign",
        formData
      );
      if (response) {
        form.resetFields();
        setIsLoading(false);
        setIsAssignModalOpen(false);
        setRefreshTable((prev) => !prev);

        notificationAPI.success({
          message: response?.data?.message,
          placement: "top",
          duration: 4,
        });
      }
    } catch (err) {
      setIsLoading(false);

      notificationAPI.error({
        message: "Error",
        description: err?.response?.data?.error,
        placement: "top",
        duration: 4,
      });
    }
  };

  // Disable past dates in DatePicker
  const disabledDate = (current) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to the start of the current day
    return current && current.valueOf() < today.getTime(); // Disable dates before today
  };

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <span style={{ color: "red" }}>*</span>}
    </>
  );
  return (
    <>
      <Modal
        title={
          <div className="w-full items-center flex justify-between">
            <Title level={4}>Assign</Title>
            <CloseCircleOutlined
              onClick={handleCancel}
              className="text-[22px] cursor-pointer"
            />
          </div>
        }
        open={isAssignModalOpen}
        closeIcon={false}
        centered
        footer={null}
        className="!rounded-full"
        width={450}
      >
        <Divider className="m-0" />
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          requiredMark={customizeRequiredMark}
        >
          <Item
            label="Assigned To"
            className="font-roboto mt-3"
            name="assigned_to"
            rules={[{ required: true, message: "Please select an assignee" }]}
          >
            <Select
              mode="multiple"
              placeholder="Select Assignee"
              size="large"
              onChange={(value) => {
                const selectedUser = userList.filter((user) =>
                  value.includes(user.id)
                );

                if (selectedUser) {
                  setAssignedToEmail(selectedUser.map((user) => user.email));
                  setAssignTo(selectedUser.map((user) => user.name));
                  setAssignToUsers(selectedUser.map((user)=>({
                    assigned_to_name: user.name,
                    assigned_to_email: user.email,
                  })))
                }
              }}
            >
              {userList.map((user) => (
                <Option key={user.id} value={user.id}>
                  {user.name}
                </Option>
              ))}
            </Select>
          </Item>

          {/* <Item
            label="Assign Date (MM/DD/YYYY)"
            className="font-roboto"
            name="assign_date"
            rules={[{ required: true, message: "Please enter a valid date" }]}
          >
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              disabledDate={disabledDate}
              format="MM/DD/YYYY"
              className="font-normal"
            />
          </Item> */}
          <Item
            label="Due Date (MM/DD/YYYY)"
            className="font-roboto"
            name="due_date"
            rules={[{ required: true, message: "Please enter a valid date" }]}
          >
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              disabledDate={disabledDate}
              format="MM/DD/YYYY"
              className="font-normal"
            />
          </Item>

          <Item>
            <CustomButton
              text={isLoading ? <Spin /> : "Assign"}
              type="primary"
              htmlType="submit"
              className="h-11 mt-4"
              disabled={isLoading}
            />
          </Item>
        </Form>

        <CustomButton
          text="Close"
          variant="outlined"
          className="h-11 mb-3"
          onClick={handleCancel}
        />
      </Modal>
    </>
  );
};

export default AssignModal;
