import React, { createContext, useEffect } from "react";
import { notification } from "antd";
import { onSnapshot, collection, query, where } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { db } from "../config/firebaseConfig";
import { PieChartOutlined } from "@ant-design/icons";

export const NotificationContext = createContext();

const FirebaseProvider = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        console.log("Start here ----")
        // Check if the current route starts with '/dashboard'
        if (!location.pathname.startsWith("/dashboard")) {
            console.log("dashboard if")
            return; // Exit if not on a dashboard route
        }
        const storedUser = JSON.parse(localStorage.getItem("user"))
        console.log({storedUser})
        const collectionName = storedUser?.data?.user?.aws_cognito_id

        console.log("Collection name", `notifications/${collectionName}/notifications`)
   // Reference to the Firestore collection
   const collectionRef = collection(db, `notifications/${collectionName}/notifications`);

   // Query to get documents created after the listener is set
   const filteredQuery = query(collectionRef, where("created_at", ">=", Date.now()));

        // Start listening for Firestore updates
        const unsubscribe = onSnapshot(
            filteredQuery,
            (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    console.log("Snapshot deducted")
                    if (change.type === "added") {
                        console.log("Change type -nadded")
                        const newMessage = change.doc.data();
                        notification.open({
                            icon: <PieChartOutlined />,
                            message: newMessage.title ||"New Message",
                            description: newMessage.content || "You have a new message!",
                            placement: "topRight",
                            duration: 10
                        });
                    }
                });
            },
            (error) => {
                console.error("Error listening for messages:", error);
            }
        );

        // Cleanup listener when leaving the route or unmounting
        return () => unsubscribe();
    }, [location]);

    return (
        <NotificationContext.Provider value={{}}>
            {children}
        </NotificationContext.Provider>
    );
};

export default FirebaseProvider;

