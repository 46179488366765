import React, { useState, useContext, useEffect } from "react";
import {
  Divider,
  Form,
  Modal,
  Typography,
  Spin,
  Upload,
  Button,
  message,
  Input,
} from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import "react-phone-number-input/style.css";
import TokenExpireModal from "../TokenExpireModal";

const { Title } = Typography;

const AddAnotherControlModel = ({
  setIsModalOpen,
  isModalOpen,
  newControlLabel,
  setNewControlLabel,
  handleAddControl,
}) => {
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title={
          <div className="w-full items-start flex justify-between">
            <Title level={4}>Add Another Control</Title>
            <CloseCircleOutlined
              onClick={handleCancel}
              className="text-[22px] cursor-pointer"
            />
          </div>
        }
        open={isModalOpen}
        closeIcon={false}
        centered
        footer={null}
        width={500}
      >
        <Divider className="m-0" />
        <div style={{ maxheight: "90vh" }}>
          <Input
            placeholder="Enter new control name"
            value={newControlLabel}
            onChange={(e) => setNewControlLabel(e.target.value)}
          />
          <div className="flex gap-4 justify-end mt-3">
            <Button key="cancel" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button
              key="add"
              type="primary"
              onClick={() => {
                handleAddControl();
                setIsModalOpen(false);
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </Modal>
      <TokenExpireModal
        isTokenExpiredModalOpen={isTokenExpiredModalOpen}
        setIsTokenExpiredModalOpen={setIsTokenExpiredModalOpen}
      />
    </>
  );
};
export default AddAnotherControlModel;
