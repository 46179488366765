import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Card,
  Collapse,
  Progress,
  Spin,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import {
  AssessmentDetailBreadCrumbList,
  AssessmentStatus,
} from "../utils/constants";
import CustomButton from "../components/CustomButton";
import Introduction from "../components/AssessmentSteps/Introduction";
import assessmentApi from "../api/assessmentAxios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import QuestionsWrapper from "../components/AssessmentSteps/QuestionsWrapper";
import { useContext } from "react";
import { notificationsContext } from "../context/Notifications";
import TokenExpireModal from "../components/TokenExpireModal";
import AssessmentSubmitModal from "../components/Assessment/AssessmentSubmitModal";
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import moment from "moment";
import AssessmentDescription from "../components/Assessment/AssessmentDescription";

const AssessmentDetail = () => {
  const { Title, Text } = Typography;
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { api: notificationAPI } = useContext(notificationsContext);
  const standardName = searchParams.get("standard");
  const assessmentId = +searchParams.get("assessment");
  const tenantId = +searchParams.get("tenant_id");
  const [assessmentCurrentStatus, setAssessmentCurrrentStatus] = useState(null);
  const [steps, setSteps] = useState(["Introduction"]);
  const [currentStep, setCurrentStep] = useState("0");
  const [introduction, setIntroduction] = useState({});
  const [questionnaire, setQuestionnaire] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [updatedAnswers, setUpdatedAnswers] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitWarningModalOpen, setIsSubmitWarningModalOpen] =
    useState(false);
  const [isSubmitSuccessModalOpen, setIsSubmitSuccessModalOpen] =
    useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [valueToAddInProgress, setValueToAddInProgress] = useState(0);
  const [totalQuestionsOfCurrentTab, setTotalQuestionsOfCurrentTab] =
    useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [fileLists, setFileLists] = useState({});
  const [questionStatus, setQuestionStatus] = useState({});
  const [questionRisk, setQuestionRisk] = useState({});
  const [selectedStatus, setSelectedStatus] = useState(false);
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const [addRecommendation, setAddRecommendation] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const response = await assessmentApi.get(
          `/assessment/${assessmentId}/${tenantId}`
        );
        const assessmentStatus = response?.data?.data?.status;

        setAssessmentCurrrentStatus(assessmentStatus);
        setAssessmentDetails(response?.data?.data);
        await getStandardDetail(assessmentStatus);
      } catch (error) {
        console.error("Something went wrong!");
      }
    })();
  }, []);

  useEffect(() => {
    setTotalQuestionsOfCurrentTab(
      questionnaire[steps[+currentStep]?.title]?.length
    );
    setCurrentQuestionIndex(0);
  }, [currentStep]);

  const getStandardDetail = async (assessmentStatus) => {
    try {
      setIsLoading(true);
      const standardDetail = await assessmentApi.get(`/standard/${id}`);

      const questionnaire = await assessmentApi.get(
        `/questionnaire/standard/${id}?assessment_id=${assessmentId}`
      );

      const newSteps = [
        {
          key: "0",
          label: "INTRODUCTION",
          title: "INTRODUCTION",
          disabled: false,
        },
        {
          key: "1",
          label: "Description",
          title: "Description",
          disabled: false,
        },
      ];

      questionnaire?.data?.data?.categories?.map((category, index) =>
        newSteps.push({
          key: (index + 1)?.toString(),
          label: category,
          title: category,
          disabled:
            assessmentStatus === AssessmentStatus.NOT_YET_STARTED
              ? true
              : false,
        })
      );

      const totalQuestions = Object.values(
        questionnaire?.data?.data?.questionnaire
      ).reduce((total, array) => total + array.length, 0);

      setValueToAddInProgress((100 / totalQuestions).toFixed(2));
      setProgressValue(Math.round(100 / totalQuestions));
      // setProgressValue((100 / totalQuestions).toFixed(2));
      setSteps(newSteps);
      setIntroduction(standardDetail?.data?.data);
      setQuestionnaire(questionnaire?.data?.data?.questionnaire);
      setIsLoading(false);
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };

  const handleNext = async () => {
    if (
      currentStep === "0" &&
      assessmentCurrentStatus == AssessmentStatus.NOT_YET_STARTED
    ) {
      try {
        setIsLoading(true);
        await assessmentApi.put(`/assessment/${assessmentId}`, {
          status: AssessmentStatus.IN_PROGRESS,
        });
        setAssessmentCurrrentStatus(AssessmentStatus.IN_PROGRESS);
        setSteps((prev) => prev.map((step) => ({ ...step, disabled: false })));
        setCurrentStep((prev) => (+prev + 2).toString());
        setIsLoading(false);
      } catch (err) {
        if (err?.response?.data?.token_expire === true) {
          setIsLoading(false);
          setIsTokenExpiredModalOpen(true);
        } else {
          setIsLoading(false);
          notificationAPI.error({
            message: "Error",
            description: err?.message,
            placement: "top",
            duration: 4,
          });
        }
      }
    } else if (+currentStep < steps.length - 1) {
      setCurrentStep((prev) => (+prev + 2).toString());
    } else {
      setIsSubmitWarningModalOpen(true);
    }
  };

  const submitAssessmentConfirmation = async () => {
    try {
      setIsSubmitWarningModalOpen(false);
      await assessmentApi.put(`/assessment/${assessmentId}`, {
        status: AssessmentStatus.COMPLETED,
      });
      setIsSubmitSuccessModalOpen(true);
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsTokenExpiredModalOpen(true);
      } else {
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };

  const submitAssessmentSuccess = (status) => {
    setIsSubmitSuccessModalOpen(status);
    navigate("/dashboard/assessment");
  };
  const handleSaveQuestionnaire = async () => {
    try {
      if (updatedAnswers.length) {
        setIsSaving(true);

        await assessmentApi.post(
          "/assessment/bulk-save-assessment-response",
          updatedAnswers
        );

        notificationAPI.success({
          message: "Success",
          description: "Assessment Saved Successfully",
          placement: "top",
          duration: 4,
        });
        setUpdatedAnswers([]);
      }
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
    setIsSaving(false);
  };

  return (
    <div className="p-5 max-h-[90vh] overflow-y-scroll">
      <Breadcrumb items={AssessmentDetailBreadCrumbList} />
      <div className="flex flex-col gap-4 mt-3 lg:flex lg:flex-row lg:justify-between lg:items-center">
        <div className="w-full md:w-auto">
          <Title level={4} className="!m-0 ">
            {standardName}
          </Title>
        </div>
        {currentStep === "0" &&
          assessmentCurrentStatus === AssessmentStatus.NOT_YET_STARTED && (
            <div className="w-full lg:w-40">
              <CustomButton
                text={"Start Assessment"}
                type="primary"
                className="bg-appLightBlue rounded-lg h-9"
                onClick={handleNext}
              />
            </div>
          )}
        {currentStep == steps.length - 1 &&
          assessmentCurrentStatus !== AssessmentStatus.NOT_YET_STARTED && (
            <div className="w-full lg:w-40">
              <CustomButton
                text={"Submit Assessment"}
                type="primary"
                className="bg-appLightBlue rounded-2xl h-9"
                onClick={handleNext}
              />
            </div>
          )}
      </div>

      <div className="w-[85vw] mt-5 block md:w-[61vw] lg:hidden ">
        <Tabs
          activeKey={currentStep.toString()}
          items={steps}
          onChange={(val) => {
            setCurrentStep(val);
            setSelectedStatus(false);
          }}
        />
      </div>
      <div
        className={`flex-wrap gap-2 border-b mb-4 mt-4 border-gray-300 hidden lg:flex  ${
          assessmentCurrentStatus === AssessmentStatus.NOT_YET_STARTED
            ? "cursor-not-allowed opacity-[0.7]"
            : "cursor-pointer"
        }`}
      >
        {steps.map((step, index) => (
          <div key={index}>
            <p
              className={`px-4 py-2  border-b-w  border-b-2 ${
                currentStep === index.toString()
                  ? "text-[#2196f3] border-[#2196f3] font-medium"
                  : "text-black border-transparent hover:text-[#2196f3]"
              }${
                assessmentCurrentStatus === AssessmentStatus.NOT_YET_STARTED
                  ? "pointer-events-none"
                  : "pointer-events-auto"
              }`}
              onClick={() => {
                if (
                  assessmentCurrentStatus !== AssessmentStatus.NOT_YET_STARTED
                ) {
                  setCurrentStep(index.toString());
                  setSelectedStatus(false);
                }
              }}
            >
              {assessmentCurrentStatus === AssessmentStatus.NOT_YET_STARTED ? (
                <Tooltip title="Please First Start Assessment">
                  {step.label}
                </Tooltip>
              ) : (
                step.label
              )}
            </p>
          </div>
        ))}
      </div>
      <div className="max-h-[60vh] overflow-y-auto bg-[#FAFAFA] p-4">
        {!["0", "1"].includes(currentStep) && (
          <div className="flex gap-1 items-center">
            <Text className="w-40">
              Question {currentQuestionIndex + 1} of{" "}
              {totalQuestionsOfCurrentTab}
            </Text>
            <Progress percent={progressValue} />
          </div>
        )}

        <div>
          {currentStep === "0" ? (
            isLoading ? (
              <Spin size="large" className="w-full" />
            ) : (
              <Introduction introduction={introduction} />
            )
          ) : currentStep === "1" ? (
            <AssessmentDescription
              assessmentDetails={assessmentDetails}
              assets={assessmentDetails?.assets}
            />
          ) : (
            <QuestionsWrapper
              name={steps[currentStep]?.title}
              data={questionnaire[steps[+currentStep]?.title]}
              setUpdatedAnswers={setUpdatedAnswers}
              handleSaveQuestionnaire={handleSaveQuestionnaire}
              assessmentId={assessmentId}
              isSaving={isSaving}
              stepsCount={steps.length}
              currentStep={currentStep}
              setProgressValue={setProgressValue}
              valueToAddInProgress={valueToAddInProgress}
              currentQuestionIndex={currentQuestionIndex}
              setCurrentQuestionIndex={setCurrentQuestionIndex}
              answers={answers}
              setAnswers={setAnswers}
              fileLists={fileLists}
              setFileLists={setFileLists}
              questionStatus={questionStatus}
              setQuestionStatus={setQuestionStatus}
              questionRisk={questionRisk}
              setQuestionRisk={setQuestionRisk}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              addRecommendation={addRecommendation}
              setAddRecommendation={setAddRecommendation}
            />
          )}
        </div>
      </div>
      <AssessmentSubmitModal
        isModalOpen={isSubmitWarningModalOpen}
        setIsModalOpen={setIsSubmitWarningModalOpen}
        title="Are you sure to submit the assessment?"
        icon={<WarningOutlined className="text-yellow-500 text-2xl" />}
        bodyText="Once you submit the assessment, You won't be able to edit it."
        buttons={[
          {
            text: "Submit",
            type: "primary",
            onClick: () => submitAssessmentConfirmation(),
          },
          {
            text: "Close",
            type: "primary",
            onClick: () => setIsSubmitWarningModalOpen(false),
          },
        ]}
      />
      <AssessmentSubmitModal
        isModalOpen={isSubmitSuccessModalOpen}
        setIsModalOpen={submitAssessmentSuccess}
        title="Assessment Submitted"
        icon={<CheckCircleOutlined className="text-green-500 text-2xl" />}
        bodyText="Your Assessment is submitted successfully."
        buttons={[
          {
            text: "Close",
            type: "primary",
            onClick: () => submitAssessmentSuccess(false),
          },
        ]}
      />
      <TokenExpireModal
        isTokenExpiredModalOpen={isTokenExpiredModalOpen}
        setIsTokenExpiredModalOpen={setIsTokenExpiredModalOpen}
      />
    </div>
  );
};

export default AssessmentDetail;
