import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Divider, Typography, Drawer, Button } from "antd";
import { ArrowLeftOutlined, MenuOutlined } from "@ant-design/icons";

import { sidebarTabs } from "../../utils/sideBarMenu";
import OrbitXLogo from "../../assets/orbitx-logo-white.png";
import OrbitXLogoWhite from "../../assets/orbitx-logo-white.png";

import Header from "./Header";

const { Text } = Typography;

const Sidebar = ({ isDrawerOpen, setIsDrawerOpen, isMobile, setIsMobile }) => {
  const location = useLocation(); // Get the current route location

  // Update `isMobile` based on screen resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const SidebarContent = (
    <div className="flex bg-appBlue flex-col h-full justify-between">
      <div className="flex flex-col gap-10">
        <div className=" bg-inherit px-4 py-2 h-16 md:bg-appNavyBlue ">
          <img
            src={OrbitXLogoWhite}
            alt="OrbitX Logo"
            className="h-12 md:h-8 mt-2"
          />
        </div>
        <ul>
          {sidebarTabs.map((tab) => (
            <li key={tab.link} className="flex justify-center">
              <Link
                to={tab.link}
                className={`p-4 w-full flex items-center gap-3 ${
                  location.pathname === tab.link
                    ? "bg-white text-appBlue transition ease-in duration-200"
                    : "text-white hover:bg-blue-400 hover:text-white hover:transition hover:ease-in-out hover:duration-200"
                }`}
                onClick={() => {
                  localStorage.setItem("headerTitle", tab.name);
                  setIsDrawerOpen(false);
                }}
              >
                <img
                  className="h-4"
                  src={
                    location.pathname === tab.link
                      ? tab.iconBlue
                      : tab.iconWhite
                  }
                />
                {tab.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {/* <div className="pb-14">
        <Divider className="bg-white mb-2" />
        <Text className="text-white pl-5">V1.0</Text>
      </div> */}
    </div>
  );

  return (
    <div>
      {isMobile ? (
        <Drawer
          // title={<img src={OrbitXLogo} alt="OrbitX Logo" className="h-8" />}
          placement="left"
          closable={true}
          onClose={() => setIsDrawerOpen(false)}
          closeIcon={<ArrowLeftOutlined className="text-white text-2xl" />}
          open={isDrawerOpen}
          bodyStyle={{ padding: 0 }}
          width={350}
          style={{
            backgroundColor: "#3B82F6",
            borderTopRightRadius: "24px",
            borderBottomRightRadius: "24px",
          }}
          headerStyle={{ borderBottom: "none" }}
          extra={<Header />}
          autoFocus={false}
        >
          {SidebarContent}
        </Drawer>
      ) : (
        <div className="bg-appBlue w-64 h-screen">{SidebarContent}</div>
      )}
    </div>
  );
};

export default Sidebar;
