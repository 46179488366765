import React from "react";
import { Typography } from "antd";

// import logoBlue from "../assets/orbitx-logo-blue.png";
import logoBlue from "../assets/welcome-orbitx-logo.png";

import Background from "../components/Background";
import CustomCard from "../components/CustomCard";
import CustomButton from "../components/CustomButton";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const Welcome = () => {
  const navigate = useNavigate();

  return (
    <Background>
      <CustomCard>
        <img src={logoBlue} className="w-96" />
        <div className="w-full sm:w-96 text-left mt-3 ">
          <Text type="secondary" className="text-sm sm:text-xl font-normal">
            Excellence in compliance and risk management.
          </Text>
        </div>
        <div className="text-left mt-10">
          <Text className="text-xl sm:text-3xl  font-medium text-[#212B36D9]">
            Welcome to OrbitX
          </Text>
        </div>
        <div className="flex flex-col gap-2 mt-6">
          <CustomButton
            text="Get Started"
            type="primary"
            onClick={() => navigate("/signup")}
          />
          <CustomButton
            text="Sign In"
            variant="outlined"
            onClick={() => navigate("/signin")}
          />
        </div>
      </CustomCard>
    </Background>
  );
};

export default Welcome;
