import React, { useState, useContext, useEffect } from "react";
import { Spin, Card, Form, Input, Select } from "antd";
import DashboardBackground from "../components/Dashboard/DashboardBackground";
import { useNavigate } from "react-router-dom";
import TokenExpireModal from "../components/TokenExpireModal";
import { notificationsContext } from "../context/Notifications";
import { ArrowLeftOutlined } from "@ant-design/icons";
import CustomButton from "../components/CustomButton";
import api from "../api/axios";

const JiraConfig = () => {
  const { api: notificationAPI } = useContext(notificationsContext);
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { Item } = Form;

  const onFinish = async (values) => {
    const formData = {
      base_url: values.jiraBaseUrl,
      username: values.jiraClientEmail,
      password: values.jiraApiToken,
    };
    try {
      setIsLoading(true);
      const response = await api.post("/jira-integration", formData);
      setIsLoading(false);
      form.resetFields();
      console.log("response", response);

      navigate(`/dashboard/integration`);
      notificationAPI.success({
        message: response?.data?.message,
        placement: "top",
        duration: 4,
      });
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.response?.data?.error,
          placement: "top",
          duration: 4,
        });
      }
    }
  };
  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <span style={{ color: "red" }}>*</span>}
    </>
  );
  return (
    <DashboardBackground>
      <div className="h-16 bg-white px-5 flex justify-start items-center gap-4">
        <ArrowLeftOutlined onClick={() => navigate(`/dashboard/integration`)} />
        <p style={{ fontSize: "18px", fontWeight: "600" }}>
          Configure Jira Networks
        </p>
      </div>
      <div className="h-[80vh] m-5 bg-white px-5 pt-5">
        <div className="mt-6">
          <Form
            className="mt-5"
            form={form}
            onFinish={onFinish}
            layout="vertical"
            requiredMark={customizeRequiredMark}
          >
            <style>
              {` .custom-spin .ant-spin-dot {
              color: white;
            }`}
            </style>
            <div className="w-full flex flex-col md:flex-row gap-4 justify-between">
              <div className="w-full md:w-1/2">
                <Item
                  label="Jira Base URL"
                  className="font-roboto font-bold"
                  name="jiraBaseUrl"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Jira Base URL",
                    },
                    {
                      type: "url",
                      message: "Please enter a valid URL",
                    },
                  ]}
                >
                  <Input
                    placeholder="Jira Base UR"
                    classNames="font-normal"
                    size="large"
                    className="font-normal"
                  />
                </Item>
              </div>
              <div className="w-full md:w-1/2">
                <Item
                  label="Jira Client Email"
                  className="font-roboto font-bold"
                  name="jiraClientEmail"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Jira Client Email",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid Email",
                    },
                  ]}
                >
                  <Input
                    placeholder="Jira Client Email"
                    classNames="font-normal"
                    size="large"
                    className="font-normal"
                  />
                </Item>
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-4 justify-between">
              <div className="w-full md:w-1/2">
                <Item
                  label="Jira API Token"
                  className="font-roboto font-bold"
                  name="jiraApiToken"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Jira API Token",
                    },
                    {
                      pattern: /^[A-Za-z0-9-_]+=[A-Z0-9]+$/,
                      message:
                        "Enter Valid API Key",
                    },
                  ]}
                >
                  <Input
                    placeholder="Jira API Token"
                    classNames="font-normal"
                    type="text"
                    size="large"
                    className="font-normal"
                  />
                </Item>
              </div>
            </div>

            <Item className="flex justify-end">
              <CustomButton
                text={
                  isLoading ? <Spin className="custom-spin" /> : "Configure"
                }
                type="primary"
                htmlType="submit"
                className="h-11 mt-4 w-28"
              />
            </Item>
          </Form>
        </div>
      </div>
      <TokenExpireModal isTokenExpiredModalOpen={isTokenExpiredModalOpen} />
    </DashboardBackground>
  );
};

export default JiraConfig;
