import React, { useContext, useEffect, useState } from "react";
import {
  DatePicker,
  Progress,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import "moment/locale/en-gb"; // Import UK locale for moment

// Set locale globally
moment.locale("en-gb");

import DashboardBackground from "../components/Dashboard/DashboardBackground";
import CustomSearch from "../components/CustomSearch";
import CustomButton from "../components/CustomButton";
import AssessmentCard from "../components/Assessment/AssessmentCard";
import { assessmentCardList } from "../utils/assessmentCardList";
import SelectTypeModal from "../components/Assessment/SelectTypeModal";
import ScheduleAssessmentModal from "../components/Assessment/ScheduleAssessmentModal";
import AssessmentSuccessModal from "../components/Assessment/AssessmentSuccessModal";
import assessmentApi from "../api/assessmentAxios";
import { notificationsContext } from "../context/Notifications";
import { useNavigate } from "react-router-dom";
import TokenExpireModal from "../components/TokenExpireModal";
import { AssessmentStatus, GLOBAL_DATE_FORMATE } from "../utils/constants";
import { AuthContext } from "../context/AuthContext";
import DueDateUpdateConfirmationModal from "../components/Assessment/DueDateUpdateConfirmationModal";
import { WarningOutlined } from "@ant-design/icons";

const { Title } = Typography;

const Assessment = () => {
  const { api: notificationAPI } = useContext(notificationsContext);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isTypeModalOpen, setIsTypeModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [scheduleAssessmentValues, setScheduleAssessmentValues] = useState({});
  const [refreshTable, setRefreshTable] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [assessmentStats, setAssessmentStats] = useState(assessmentCardList);
  const [isDueDateUpdateModal, setIsDueDateUpdateModal] = useState(false);
  const [assessmentIdForUpdatedDueDate, setAssessmentIdForUpdatedDueDate] =
    useState(null);
  const [assessmentNameForUpdatedDueDate, setAssessmentNameForUpdatedDueDate] =
    useState("");
  const [
    assessmentPerviousDueForUpdatedDueDate,
    setAssessmentPerviousDueForUpdatedDueDate,
  ] = useState("");
  const [
    assessmentUpdatedDueForUpdatedDueDate,
    setAssessmentUpdatedDueForUpdatedDueDate,
  ] = useState("");

  // Update the date in the dataSource
  const handleDateChange = (date, dateString, record) => {
    const assessmentName = record?.name;
    setAssessmentNameForUpdatedDueDate(assessmentName);
    setAssessmentIdForUpdatedDueDate(record?.id);

    let previousDate = new Date(record?.due_date);
    previousDate = previousDate.toLocaleDateString("en-GB");
    setAssessmentPerviousDueForUpdatedDueDate(previousDate);

    setAssessmentUpdatedDueForUpdatedDueDate(dateString);

    setIsDueDateUpdateModal(true);

    // const updatedDate = new Date(dateString);

    // due_date: newDate.toISOString(),

    // const updatedDataSource = dataSource.map((item) => {
    //   if (item.key === record.key) {
    //     return { ...item, date: dateString };
    //   }
    //   return item;
    // });
    // setDataSource(updatedDataSource);
  };
  const updateDueDateForAssessment = async () => {
    const dueDate = {
      dueDate: assessmentUpdatedDueForUpdatedDueDate,
    };
    try {
      setIsLoading(true);
      const response = await assessmentApi.put(
        `/assessment/${assessmentIdForUpdatedDueDate}`,
        dueDate
      );
      setIsLoading(false);
      setIsDueDateUpdateModal(false);
      notificationAPI.success({
        message: response?.data?.message,
        placement: "top",
        duration: 4,
      });
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const tenantId = storedUser?.data?.user?.tenant_id;
  useEffect(() => {
    (async () => await getAssessments())();
  }, [refreshTable, searchText]);

  const getAssessments = async (
    currentPage = 1,
    pageSize = 10,
    sort_by = "createdAt",
    sort_order = "desc"
  ) => {
    try {
      setIsLoading(true);
      const params = {
        page_number: currentPage,
        page_limit: pageSize,
        sort_by: sort_by,
        sort_order: sort_order,
        tenant_id: tenantId,
      };

      if (searchText.length > 0) params.search_text = searchText;

      const assessments = await assessmentApi.get("/assessment", { params });
      setData(assessments?.data?.data?.rows);

      // Update assessmentStats by mapping through initial state and setting count based on API data
      const updatedStats = assessmentCardList.map((stat) => {
        const apiStat = assessments?.data?.data?.assessmentStats?.find(
          (item) => item.status === stat.status
        );
        return {
          ...stat,
          count: apiStat ? apiStat.count : 0, // Keep bgColor, update count if found in API data
        };
      });
      setAssessmentStats(updatedStats);
      setTableParams({
        pagination: {
          current: currentPage,
          pageSize,
          total: assessments?.data?.data?.count,
        },
      });
      setIsLoading(false);
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };

  const generateReportUrl = async (assessmentId) => {
    try {
      setIsLoading(true);
      const response = await assessmentApi.post("/assessment/generate-report", { assessment_id: assessmentId });
      const signedUrl = response?.data?.data;
      const message = response?.data?.message
      if (signedUrl) {
        setIsLoading(false);
        window.open(signedUrl, "_blank");
      } else {
        setIsLoading(false);
        notificationAPI.success({
          message: "Success",
          description: message,
          placement: "top",
          duration: 4,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching signed URL:", error);
      notificationAPI.error({
        message: "Error",
        description: error?.message,
        placement: "top",
        duration: 4,
      });
    }
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    const sort_by = sorter.field; // Get the field to sort by
    const sortOrder = sorter.order;
    const sort_order =
      sortOrder === "ascend"
        ? "asc"
        : sortOrder === "descend"
          ? "desc"
          : undefined;
    setTableParams({
      pagination,
    });
    await getAssessments(
      pagination.current,
      pagination.pageSize,
      sort_by,
      sort_order
    );
  };

  const showTypeModal = () => {
    setIsTypeModalOpen(true);
  };

  const handleCancelDate = () => {
    const updateRecord = data.map((item) => {
      if (item.id === assessmentIdForUpdatedDueDate) {
        return {
          ...item,
          due_date: assessmentPerviousDueForUpdatedDueDate,
        };
      }
      return item;
    });
    setData(updateRecord);
    setIsDueDateUpdateModal(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: true,
      // sorter: (a, b) => a.type.localeCompare(b.type),
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Assigned To",
      dataIndex: "assigned_to",
      key: "assigned_to",
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (text, record) => {
        const isPickerDisabled =
          record.status !== "In Progress" &&
          record.status !== "Not Yet Started";
        const date = new Date(record.due_date);
        const formattedDate = date.toLocaleDateString("en-GB");
        if (isPickerDisabled) {
          return <span>{formattedDate}</span>;
        }

        return (
          <DatePicker
            defaultValue={dayjs(formattedDate, GLOBAL_DATE_FORMATE)}
            onChange={(date, dateString) =>
              handleDateChange(date, dateString, record)
            }
            format={GLOBAL_DATE_FORMATE}
            allowClear={false}
            disabled={isPickerDisabled}
            style={{
              border: "none",
              cursor: isPickerDisabled ? "not-allowed" : "pointer",
            }}
            disabledDate={(current) =>
              current.isBefore(moment().subtract(1, "day"))
            }
          />
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => {
        let color = "";

        status === AssessmentStatus.NOT_YET_STARTED
          ? (color = "yellow")
          : status === AssessmentStatus.IN_PROGRESS
            ? (color = "red")
            : status === AssessmentStatus.REVIEWING
              ? (color = "purple")
              : status === AssessmentStatus.COMPLETED
                ? (color = "green")
                : (color = "gold");

        return (
          <Tag
            color={color}
            key={status}
            className="rounded-2xl font-semibold text-sm"
            bordered={false}
          >
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      render: (_, { score }) => <Progress percent={score} size="small" />,
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <div className="w-full flex gap-2 justify-center">
          <div className="w-14">
            <CustomButton
              text="View"
              variant="outlined"
              className="h-7"
              disabled={
                record.status === AssessmentStatus.COMPLETED ||
                record.type == "IEC 62443-2-1"
              }
              onClick={() =>
                navigate(
                  `/dashboard/assessment/assessment-detail/${record?.standard_id}?standard=${record.type}&assessment=${record.id}&tenant_id=${user?.data?.user?.tenant_id}`
                )
              }
            />
          </div>
          <div className="w-fit">
            <CustomButton
              text="View Report"
              variant="outlined"
              className="h-7"
              disabled={
                record.type == "IEC 62443-2-1" ||
                record?.status === AssessmentStatus.NOT_YET_STARTED
              }
              onClick={() => generateReportUrl(record.id)}
            />
          </div>
        </div>
      ),
      align: "center",
    },
  ];
  const handleNavigation = () => {
    navigate(`/dashboard/assessment/scheduleAssessment`);
  };

  return (
    <DashboardBackground>
      <div className="flex flex-col mt-7 gap-4 bg-transparent px-5 lg:h-16 lg:bg-white lg:px-5 lg:mt-0  lg:flex lg:flex-row lg:justify-between lg:items-center">
        <CustomSearch
          setSearchText={setSearchText}
          className={"w-full lg:w-auto"}
        />
        <div className="w-full lg:w-48">
          <CustomButton
            text="Schedule Assessment"
            type="primary"
            className="h-12 font-normal text-base lg:h-9 lg:font-normal lg:text-base"
            onClick={handleNavigation}
          // onClick={showTypeModal}
          />
        </div>
        <SelectTypeModal
          isTypeModalOpen={isTypeModalOpen}
          setIsTypeModalOpen={setIsTypeModalOpen}
          setSelectedType={setSelectedType}
          showTypeModal={showTypeModal}
          setIsScheduleModalOpen={setIsScheduleModalOpen}
        />
        <ScheduleAssessmentModal
          isScheduleModalOpen={isScheduleModalOpen}
          setIsScheduleModalOpen={setIsScheduleModalOpen}
          selectedType={selectedType}
          setIsSuccessModalOpen={setIsSuccessModalOpen}
          setScheduleAssessmentValues={setScheduleAssessmentValues}
          setRefreshTable={setRefreshTable}
        />
        <AssessmentSuccessModal
          isSuccessModalOpen={isSuccessModalOpen}
          setIsSuccessModalOpen={setIsSuccessModalOpen}
        />
        <TokenExpireModal
          isTokenExpiredModalOpen={isTokenExpiredModalOpen}
          setIsTokenExpiredModalOpen={setIsTokenExpiredModalOpen}
        />
        <DueDateUpdateConfirmationModal
          isModalOpen={isDueDateUpdateModal}
          setIsModalOpen={setIsDueDateUpdateModal}
          title="Are you sure to update the Due Date?"
          icon={<WarningOutlined className="text-yellow-500 text-2xl" />}
          bodyText={`You updating the due date of ${assessmentNameForUpdatedDueDate} from ${assessmentPerviousDueForUpdatedDueDate} to ${assessmentUpdatedDueForUpdatedDueDate}`}
          buttons={[
            {
              text: isLoading ? <Spin className="custom-spin" /> : "Submit",
              type: "primary",
              onClick: () => updateDueDateForAssessment(),
            },
            {
              text: "Cancel",
              type: "primary",
              onClick: () => handleCancelDate(),
            },
          ]}
        />
      </div>

      <div className="mt-10 bg-transparent m-0 px-5 pt-2 lg:h-[80vh] lg:overflow-y-scroll lg:m-5 lg:bg-white lg:px-5 lg:pt-5">
        <Title level={3} className="hidden lg:block">
          Assessment
        </Title>
        <div className="flex gap-5 flex-col lg:flex lg:flex-row lg:gap-5 lg:w-full">
          {assessmentStats.map((card, index) => (
            <AssessmentCard
              key={index}
              bgColor={card.bgColor}
              title={card.status}
              value={card.count}
            />
          ))}
        </div>
        <div className="mt-6 overflow-x-scroll lg:mt-6 lg:overflow-x-hidden">
          <div className="w-[90vw] md:w-[60vw] lg:w-auto">
            <Table
              columns={columns}
              dataSource={data}
              loading={isLoading}
              pagination={{
                ...tableParams.pagination,
                position: ["bottomCenter"],
                showSizeChanger: true,
              }}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
    </DashboardBackground>
  );
};

export default Assessment;
