import React, { useEffect, useState, useContext } from "react";
import { Table, Typography, Spin, Button, Row, Col } from "antd";
import DashboardBackground from "../components/Dashboard/DashboardBackground";
import { notificationsContext } from "../context/Notifications";
import { useNavigate } from "react-router-dom";
import TokenExpireModal from "../components/TokenExpireModal";
import CustomAssessmentCard from "../components/CustomAssessmentList/CustomAssessmentCard";
import assessmentApi from "../api/assessmentAxios";
const { Title } = Typography;

const CustomAssessment = () => {
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const { api: notificationAPI } = useContext(notificationsContext);
  const navigate = useNavigate();
  const [customListingData, setCustomListingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => await getStandard())();
  }, []);

  const getStandard = async () => {
    try {
      setIsLoading(true);
      const params = {
        standard_type: "CUSTOM",
      };
      const data = await assessmentApi.get("/standard", { params });
      setCustomListingData(data?.data?.data);
      setIsLoading(false);
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };

  const handleDeleteAssessment = async (id) => {
    try {
      const data = await assessmentApi.delete(`/standard/${id}`);
      getStandard();
      notificationAPI.success({
        message: data?.data?.message,
        placement: "top",
        duration: 4,
      });
    } catch (error) {
      notificationAPI.error({
        message: "Error",
        description: error?.message,
        placement: "top",
        duration: 4,
      });
    }
  };

  return (
    <DashboardBackground>
      <div className="h-16 bg-white mt-3 lg:mt-0 px-5 flex flex-col lg:flex-row lg:justify-between items-center">
        <Title level={3} className="hidden lg:block">
          Custom Assessment
        </Title>
        <div className="w-34 ">
          <Button
            type="primary"
            className="h-9"
            onClick={() => navigate("/dashboard/assessment/custom-assessment")}
          >
            Create Custom Assessment
          </Button>
        </div>
      </div>
      <div className="h-[80vh] lg:m-5 bg-white px-5 lg:pt-5 ">
        {isLoading ? (
          <Spin className="flex justify-center items-center mt-5" />
        ) : customListingData.length > 0 ? (
          <Row gutter={[8, 8]}>
            {customListingData.map((assessment) => (
              <Col span={3} xs={24} md={3}>
                <CustomAssessmentCard
                  assessment={assessment}
                  key={assessment.id}
                  handleDeleteAssessment={handleDeleteAssessment}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <div className="flex justify-center items-center mt-5">
            No Custom Assessment available.
          </div>
        )}
      </div>
    </DashboardBackground>
  );
};
export default CustomAssessment;
