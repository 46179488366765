import React, { useContext, useState } from "react";
import { Typography, Form, Input, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import Background from "../components/Background";
import CustomCard from "../components/CustomCard";
import CustomButton from "../components/CustomButton";
import SignUpCode from "../components/SignUpCode";
import api from "../api/axios";
import { notificationsContext } from "../context/Notifications";

const { Title, Text, Link } = Typography;

const SignUp = () => {
  const { Item } = Form;
  const navigate = useNavigate();
  const { api: notificationAPI } = useContext(notificationsContext);
  const [isSignUpCode, setIsSignUpCode] = useState(false);
  const [email, setEmail] = useState("");
  const [value, setValue] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validatePhoneNumber = (_, value) => {
    if (value === undefined)
      return Promise.reject(
        new Error("Please enter a valid phone number with country code")
      );
    const numericPhone = value.replace(/\D/g, ""); // Remove non-numeric characters
    if (numericPhone.length >= 11 && numericPhone.length <= 14) {
      return Promise.resolve(); // Pass validation for European numbers
    }
    if (!isPossiblePhoneNumber(value)) {
      return Promise.reject(new Error("The phone number is not possible"));
    }

    if (!isValidPhoneNumber(value)) {
      return Promise.reject(new Error("The phone number is not valid"));
    }
    return Promise.reject(
      new Error("Please enter a valid phone number with country code")
    );
  };

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      const formattedValues = {
        name: values.name,
        email: values.email,
        password: values.password,
        // phone_number: values.phone_number.replace(/\s+/g, "").slice(0, 12), // Remove all non-numeric characters
        phone_number: value,
      };

      await api.post("/signup", formattedValues);

      setEmail(formattedValues.email);
      setIsLoading(false);
      setIsSignUpCode(true);
    } catch (err) {
      setIsLoading(false);
      notificationAPI.error({
        message: "Error",
        description: err?.response?.data?.error,
        placement: "top",
        duration: 4,
      });
    }
  };

  const validateFullName = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter your full name"));
    }
    const nameParts = value.trim().split(" ");
    if (nameParts.length !== 2) {
      return Promise.reject(
        new Error("Full Name field should contain First and Last Name")
      );
    }
    const [firstName, lastName] = nameParts;
    if (firstName.length < 3 || firstName.length > 50) {
      return Promise.reject(
        new Error("First Name must be between 3 and 50 characters")
      );
    }
    if (lastName.length < 3 || lastName.length > 50) {
      return Promise.reject(
        new Error("Last Name must be between 3 and 50 characters")
      );
    }
    return Promise.resolve();
  };
  const validatePassword = (value) => {
    const errors = [];
    if (value.length < 8) errors.push("at least 8 characters long");
    if (!/[A-Z]/.test(value)) errors.push("1 uppercase letter");
    if (!/[a-z]/.test(value)) errors.push("1 lowercase letter");
    if (!/[0-9]/.test(value)) errors.push("1 number");
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value))
      errors.push("1 special character");

    if (errors.length > 0) {
      setPasswordError(`Your password must be ${errors.join(", ")}.`);
    } else {
      setPasswordError("");
    }
  };
  return (
    <Background>
      <div className="mt-20 sm:mt-0">
        {!isSignUpCode ? (
          <CustomCard>
            <Title level={2} className="!font-bold">
              Sign Up
            </Title>
            <style>
              {`
              .PhoneInput{
               width:100%;
              border: 1px solid #d9d9d9 !important; /* Optional: set a border color */
              height: 40px;
             }
              .PhoneInput--focus{
              border: 1px solid #1890ff !important;
              }
               .PhoneInput .PhoneInputCountry {
                display:none;
                align-item:center;
                justify-content:center;
                width: 15%; 
                border: none;
                outline: none;
                }
               .PhoneInput .PhoneInputInput{
               max-width:90% !important;
               outline: none;
               height: 35px;
               margin-left: 12px
               } 
          `}
            </style>

            <Form onFinish={onFinish} layout="vertical">
              <Item
                label="Full Name"
                name="name"
                rules={[
                  {
                    required: true,
                    validator: validateFullName,
                  },
                ]}
              >
                <Input placeholder="Enter your full name" size="large" />
              </Item>

              <Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        value &&
                        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                          value
                        )
                      ) {
                        return Promise.reject(
                          new Error("The email is not valid")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="test@test.com" size="large" />
              </Item>

              <Item
                label="Contact"
                className="font-roboto "
                name="contact"
                rules={[
                  { required: true, message: "" },
                  { validator: validatePhoneNumber },
                ]}
              >
                <PhoneInput
                  placeholder="[Country Code] [Phone Number] (+31 12 345 6789)"
                  // defaultCountry="GB"
                  international
                  withCountryCallingCode
                  countryCallingCodeEditable={true}
                  value={value}
                  onChange={(e) => setValue(e)}
                  className="rounded-lg font-normal PhoneInput"
                />
              </Item>
              <Item
                label="Password"
                name="password"
                rules={[
                  {
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(
                          new Error("Please enter your password")
                        );
                      }
                      validatePassword(value);
                      if (passwordError) {
                        return Promise.reject(new Error(passwordError));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter your password"
                  size="large"
                  onChange={(e) => validatePassword(e.target.value)}
                />
              </Item>

              <Item
                label="Confirm Password"
                name="confirm_password"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Password & Confirm Password do not match")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirm your password"
                  size="large"
                />
              </Item>

              <Item>
                <CustomButton
                  text={isLoading ? <Spin /> : "Create Account"}
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                />
              </Item>
            </Form>

            <div className="w-full flex gap-1 justify-center">
              <Text type="secondary">Already have an account?</Text>
              <Link
                className="!underline !text-appBlue"
                onClick={() => navigate("/signin")}
              >
                Sign In
              </Link>
            </div>
          </CustomCard>
        ) : (
          <SignUpCode email={email} />
        )}
      </div>
    </Background>
  );
};

export default SignUp;
