import React, { useContext } from "react";
import DashboardBackground from "../components/Dashboard/DashboardBackground";
import { Card, Typography } from "antd";
import CustomButton from "../components/CustomButton";
import NozomiLogo from "../assets/nozomi-logo.png";
import ModatLogo from "../assets/modat-logo.png";
import JiraLogo from "../assets/jira-log.png";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { THIRD_PARTY_NAME } from "../utils/constants";

const { Title, Text } = Typography;

const Integration = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const IntegrationPlatforms = [
    {
      Title: "Nozomi Networks",
      Type: "Threat Analysis",
      Link: "https://www.google.com",
      Logo: NozomiLogo,
      Description:
        "Nozomi Networks keeps operational technology cyber resilient with OT network and endpoint visibility, threat detection, and AI-powered analysis.",
    },
    {
      Title: "Modat (Coming soon)",
      Type: "Internet Index Search Solution",
      Link: "https://www.google.com",
      Logo: ModatLogo,
      Description:
        "Modat Internet Index Solution: EU-crafted search engine made by cyber specialists for cyber specialists.",
    },
    {
      Title: "Jira",
      Type: "Project management",
      Link: "https://www.atlassian.com/software/jira",
      Logo: JiraLogo,
      Description:
        "Use Timelines to help your team and stakeholders stay in sync about projects and deadlines",
    },
  ];
  const handleNavigation = () => {
    navigate(`/dashboard/integration/configuration`);
  };

  const jiraConnected =
    user?.data?.thirdPartyInfo.length > 0 &&
    user.data.thirdPartyInfo.some((jira) => jira.third_party_name === "JIRA");
  const nozomiConnected =
    user?.data?.thirdPartyInfo.length > 0 &&
    user.data.thirdPartyInfo.some(
      (nozomi) => nozomi.third_party_name === THIRD_PARTY_NAME
    );

  return (
    <DashboardBackground>
      <div className="h-[90vh] mt-5 lg:mt-0">
        <div className="hidden h-20 bg-white w-full lg:flex items-center pl-8 ">
          <Title level={3} className="!m-0 ">
            Integrations
          </Title>
        </div>

        <div className="lg:m-5 flex items-center justify-center lg:items-stretch  lg:justify-start flex-wrap gap-5 mt-3">
          {IntegrationPlatforms.map((integration) => (
            <Card
              className="shadow-md !w-[360px] lg:!w-[400px]"
              title={
                <div className="flex items-center justify-between my-4">
                  <div className="flex gap-3">
                    <img src={integration.Logo} className="w-14 h-14" />
                    <div className="flex flex-col justify-center">
                      <Text className="!m-0">{integration.Title}</Text>
                      <Text
                        type="secondary"
                        className="!m-0 !text-xs font-normal"
                      >
                        {integration.Type.slice(0, 22)}
                      </Text>
                    </div>
                  </div>
                  <div>
                    {integration.Title === "Modat (Coming soon)" ? (
                      <CustomButton
                        text="Connect"
                        type="primary"
                        className="h-9 bg-appLightBlue"
                        disabled={true}
                        onClick={() =>
                          window.open(
                            integration.Link,
                            "_blank",
                            "noopener,noreferrer"
                          )
                        }
                      />
                    ) : integration.Title === "Jira" ? (
                      <CustomButton
                        text={jiraConnected ? "Connected " : "Connect"}
                        type="primary"
                        className="h-9 bg-appLightBlue"
                        disabled={jiraConnected}
                        onClick={() =>
                          navigate("/dashboard/integration/configuration/jira")
                        }
                      />
                    ) : (
                      <CustomButton
                        text={nozomiConnected ? "Connected " : "Connect"}
                        type="primary"
                        disabled={nozomiConnected}
                        className="h-9 bg-appLightBlue"
                        onClick={handleNavigation}
                      />
                    )}
                  </div>
                </div>
              }
              style={{
                width: 400,
              }}
            >
              <div className="flex flex-col gap-1">
                <Text className="!m-0 font-medium">Description</Text>
                <Text type="secondary" className="!m-0 !text-xs font-normal">
                  {integration.Description}
                </Text>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </DashboardBackground>
  );
};

export default Integration;
