import React from "react";
import { Card } from "antd";

const CustomCard = ({ children }) => {
  return (
    <Card
      bordered={false}
      className="w-[340px] rounded-lg sm:h-fit sm:py-3 sm:rounded-3xl sm:w-[450px] "
    >
      {children}
    </Card>
  );
};

export default CustomCard;
