import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import api from "../../api/axios";
import { Spin } from "antd";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";

const Content = () => {
  const { user } = useContext(AuthContext);
  const [isUrlLoaded, setIsUrlLoaded] = useState(false);
  const [embedURL, setEmbedURL] = useState("");
  const tenantId = user?.data?.user?.tenant_id

  useEffect(() => {
    const fetchAndEmbedDashboard = async () => {
      if (!tenantId) return;

      setIsUrlLoaded(true);

      try {
        // Fetch the embed URL
        const res = await api.get(`/user/get-dashboard-url/${tenantId}`);
        const url = res?.data?.data?.embedUrl;
        setEmbedURL(url || ""); // Fallback to an empty string if URL is missing
      } catch (error) {
        console.error("Failed to fetch embed URL:", error);
        setEmbedURL(""); // Ensure embedURL is empty if an error occurs
      } finally {
        setIsUrlLoaded(false);
      }
    };

    fetchAndEmbedDashboard();
  }, [tenantId]);

  useEffect(() => {
    const embedDashboard = async () => {
      if (!embedURL) return; // Only embed if the URL exists

      const container = document.getElementById("embedding-container");
      if (!container) {
        console.error("Embedding container is not available.");
        return;
      }

      try {
        // Start embedding with a timeout
        const timeout = setTimeout(() => {
          setEmbedError("Embedding timed out.");
        }, 10000); // Set timeout after 10 seconds

        const embeddingContext = await QuickSightEmbedding.createEmbeddingContext();
        const frameOptions = {
          url: embedURL,
          container,
          height: "100%",
          width: "100%",
        };

        const contentOptions = {
          toolbarOptions: {
            export: true,
            undoRedo: false,
            reset: false,
          },
          sheetOptions: {
            initialSheetId: "<YOUR_SHEET_ID>", // Replace with your sheet ID
            singleSheet: false,
            emitSizeChangedEventOnSheetChange: false,
          },
          attributionOptions: {
            overlayContent: false,
          },
        };

        // Embed the dashboard
        await embeddingContext.embedDashboard(frameOptions, contentOptions);

        clearTimeout(timeout); // Clear the timeout if embedding succeeds
      } catch (embedError) {
        console.error("Error during QuickSight embedding:", embedError);
      }
    };

    embedDashboard();
  }, [embedURL]); // Only embed when embedURL changes

  return (
    <div style={{ height: "100vh" }}>
      {isUrlLoaded ? (
        <Spin size="large" className="w-full h-full flex items-center justify-center" />
      ) : embedURL ? (
        <div id="embedding-container" style={{ position: "relative", height: "90%", width: "100%", marginBottom: "10px"}} />
      ) : (
        <p style={{ textAlign: "center", marginTop: "20px" }}>No dashboard available</p>
      )}
    </div>
  );
};

export default Content;
