import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Breadcrumb,
  Card,
  Progress,
  Spin,
  Tabs,
  Tooltip,
  Typography,
  Tag,
  Badge,
  Input,
  Upload,
  Button,
  Form,
  Select,
} from "antd";
import DashboardBackground from "../components/Dashboard/DashboardBackground";
import { RemediationDetailBreadCrumbList } from "../utils/constants";
import {
  ArrowLeftOutlined,
  UserOutlined,
  DownloadOutlined,
  CloseOutlined,
  LinkOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TokenExpireModal from "../components/TokenExpireModal";
import { notificationsContext } from "../context/Notifications";
import { AuthContext } from "../context/AuthContext";
import assessmentApi from "../api/assessmentAxios";
import moment from "moment";
import CustomBadge from "../components/CustomBadge";
import CustomButton from "../components/CustomButton";
const RemediationDetail = () => {
  const { Title, Text } = Typography;
  const { TextArea } = Input;
  const { Item } = Form;
  const { Option } = Select;

  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const { api: notificationAPI } = useContext(notificationsContext);
  const { user } = useContext(AuthContext);
  const [fileLists, setFileLists] = useState({});
  const [isAiSuggestionGenerated, setIsAiSuggestionGenerated] = useState(false);
  const [actionValue, setActionValue] = useState("");
  // State to manage warning messages
  const [warningMessages, setWarningMessages] = useState({});
  const question = { id: "q1", text: "Sample Question" };
  // Upload props for the Upload component
  const uploadProps = {
    multiple: true,
    onRemove: (file) => {
      setFileLists((prev) => ({
        ...prev,
        [question?.id]: prev[question?.id]?.filter((f) => f.uid !== file.uid),
      }));
    },
    beforeUpload: (file) => {
      setFileLists((prev) => ({
        ...prev,
        [question?.id]: [...(prev[question?.id] || []), file],
      }));
      return false; // Prevent automatic upload
    },
    fileList: fileLists[question?.id] || [],
  };

  // Handle click for the upload button
  const handleUploadClick = () => {
    if (!fileLists[question?.id]?.length) {
      setWarningMessages((prev) => ({
        ...prev,
        [question?.id]: "Please upload at least one file.",
      }));
    } else {
      setWarningMessages((prev) => ({
        ...prev,
        [question?.id]: null,
      }));
      console.log("Files ready for upload:", fileLists[question?.id]);
    }
  };
  const fetchData = async () => {
    try {
      setIsLoading(true);

      const res = await assessmentApi.get(
        `/remediation/get-by-id/?id=${+id}&tenant_id=${
          user?.data?.user?.tenant_id
        }`
      );
      setIsLoading(false);
      setData(res?.data?.data);
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };

  const submitForReview = async () => {
    const formData = {
      id: data?.id,
      requirement_updated_status: "In Place",
      moving_reason: actionValue,
    };
    try {
      setIsLoading(true);
      await assessmentApi.post("/remediation/submit-for-review", formData);
      setIsLoading(false);
      setActionValue("");
      fetchData();
      notificationAPI.success({
        message: "Remediation is submitted for Review Successfully!",
        placement: "top",
        duration: 4,
      });
      navigate("/dashboard/remediation");
    } catch (error) {
      if (error?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: error?.response?.data?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };
  const submitForResolved = async () => {
    const formData = {
      id: data?.id,
    };
    try {
      setIsLoading(true);
      await assessmentApi.post("/remediation/resolve", formData);
      setIsLoading(false);
      fetchData();
      notificationAPI.success({
        message: "Remediation is resolved Successfully!",
        placement: "top",
        duration: 4,
      });
      navigate("/dashboard/remediation");
    } catch (error) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };
  const requirementStatus = [
    { label: "Pending", value: "Pending" },
    { label: "In Progress", value: "In Progress" },
    { label: "Resolved", value: "Resolved" },
    { label: "To Do", value: "To Do" },
  ];

  ("");
  useEffect(() => {
    (async () => fetchData())();
  }, [id]);

  console.log(
    "actionValue",
    data?.assign_to_attributes?.assigned_to_email === user?.data?.user?.email &&
      data?.status === "In Progress"
  );

  return (
    <DashboardBackground>
      <div className="h-16 bg-white px-5 flex flex-col  justify-center items-start  gap-2">
        <div>
          <Breadcrumb items={RemediationDetailBreadCrumbList} />
        </div>
        <div className="flex flex-row gap-3">
          <ArrowLeftOutlined
            onClick={() => navigate(`/dashboard/remediation`)}
          />
          <p style={{ fontSize: "18px", fontWeight: "600" }}>
            Requirement Details
          </p>
        </div>
      </div>
      <div className="h-[75vh] overflow-scroll overflow-x-hidden md:m-5 bg-white px-5 pt-5">
        <Card className="bg-slate-100">
          <div className="flex flex-col gap-4 md:gap-0  md:flex-row md:justify-between md:items-start">
            <div className="flex flex-col  md:flex-row justify-start md:items-start gap-5">
              <div className="text-black md:max-w-[750px]">
                {data?.question_statement ?? "-"}
              </div>
              <div className="flex flex-row justify-start items-center gap-3">
                {/* <div>
                  <Avatar icon={<UserOutlined />} />
                </div> */}
                <div>
                  <Tag className="md:text-sm" color="error">
                    {data?.remediation_severity ?? "-"}
                  </Tag>
                </div>
              </div>
            </div>
            <div>
              <CustomBadge data={[{ status: data?.status }]} />
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-start md:items-center gap-5 mt-3">
            <div className="flex justify-between flex-row md:justify-start items-center gap-3">
              <div className="text-stone-500">Assigned To:</div>
              <div>{data?.assigned_to ?? "-"}</div>
            </div>
            <div className="flex justify-between flex-row md:justify-start items-center gap-3">
              <div className="text-stone-500">Assigned Date:</div>
              <div>
                {moment(data?.assigned_date).format("MM/DD/YYYY") ?? "-"}
              </div>
            </div>
            <div className="flex justify-between flex-row md:justify-start items-center gap-3">
              <div className="text-stone-500">Due Date:</div>
              <div>{moment(data?.due_date).format("MM/DD/YYYY") ?? "-"}</div>
            </div>
          </div>
          <div className="mt-5">
            <p className="font-medium text-sm mb-2">Current Observation</p>
            <TextArea
              value={data?.answer ?? "No Answer Provided"}
              disabled
              rows={4}
              style={{
                backgroundColor: "white",
                color: "black",
                padding: "10px",
                resize: "none",
              }}
            />
          </div>
          {/* <div className="mt-5 w-full">
            <p className="font-medium text-sm mb-2">Requirement Status</p>
            <Select
              placeholder="Select Status"
              size="large"
              className="w-full md:w-1/2"
            >
              {requirementStatus.map((status) => (
                <Option key={status.value} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </Select>
          </div> */}
          <div className="mt-5">
            <p className="font-medium text-sm mb-2">Recommendation</p>
            <TextArea
              value={data?.recommendation ?? "No recommendation Provided"}
              disabled
              style={{
                backgroundColor: "white",
                color: "black",
                padding: "10px",
                resize: "none",
              }}
            />
          </div>
          <div className="my-2">
            <p className="font-medium text-sm mb-2">Action</p>

            <TextArea
              value={data?.status == "In Progress" ? actionValue: data?.moving_reason}
              onChange={(e) => {
                setActionValue(e.target.value);
              }}
              rows={4}
              style={{
                backgroundColor: "white",
                color: "black",
                padding: "10px",
                resize: "none",
              }}
            />
          </div>
          {/* <div className="mt-5 mb-2 text-black text-base">Evidence</div>
          {data?.assign_to_attributes?.assigned_to_email ===
            user?.data?.user?.email && (
            <div className="flex flex-col gap-2">
              <div className="flex gap-2 flex-wrap">
                {fileLists[question?.id]?.map((file, index) => (
                  <div
                    key={index}
                    className="border border-appLightBlue flex items-center gap-1 px-1"
                  >
                    <LinkOutlined className="text-appLightBlue" />
                    <Text className="text-xs text-appLightBlue">
                      {file.name || file}
                    </Text>
                    <Button
                      type="text"
                      icon={
                        <CloseOutlined className="!text-xs text-gray-500" />
                      }
                      onClick={() => uploadProps.onRemove(file)}
                      className="h-6 !w-4"
                    />
                  </div>
                ))}
              </div>

              <div className="flex flex-col gap-1">
                <Upload {...uploadProps} showUploadList={false}>
                  <Button
                    type="primary"
                    className={`bg-appLightBlue`}
                    onClick={handleUploadClick}
                  >
                    <LinkOutlined /> Attach
                  </Button>
                </Upload>
              </div>
            </div>
          )} */}
        </Card>
        {data?.assign_to_attributes?.assigned_to_email ===
          user?.data?.user?.email && data?.status === "In Progress" ? (
          <div className="mt-5 mb-5 flex justify-end items-end">
            <CustomButton
              text={
                isLoading ? (
                  <Spin className="custom-spin" />
                ) : (
                  "Submit for Review"
                )
              }
              type="primary"
              className="h-9 !w-36 rounded-md bg-appLightBlue"
              onClick={submitForReview}
            />
          </div>
        ) : data?.assign_from_attributes?.assigned_by_email ===
            user?.data?.user?.email && data?.status === "In Review" ? (
          <div className="mt-5 mb-5 flex justify-end items-end">
            <CustomButton
              text={isLoading ? <Spin className="custom-spin" /> : "Resolve"}
              type="primary"
              className="h-10 !w-32 rounded-md bg-appBlue"
              onClick={submitForResolved}
            />
          </div>
        ) : null}
      </div>

      <TokenExpireModal isTokenExpiredModalOpen={isTokenExpiredModalOpen} />
    </DashboardBackground>
  );
};

export default RemediationDetail;
