import React, { useContext, useState } from "react";
import { Typography, Form, Input, Spin } from "antd";
import Background from "../components/Background";
import CustomCard from "../components/CustomCard";
import CustomButton from "../components/CustomButton";
import { useNavigate } from "react-router-dom";
import api from "../api/axios";
import { AuthContext } from "../context/AuthContext";
import { notificationsContext } from "../context/Notifications";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";

const { Title } = Typography;
const ForgotPassword = () => {
  const { Item } = Form;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { api: notificationAPI } = useContext(notificationsContext);

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      const res = await api.post("/forgot-password", values);
      setIsLoading(false);
      navigate(`/reset-password/${encodeURIComponent(values.email)}`);
      notificationAPI.success({
        message: "Success",
        description: res?.data?.message,
        placement: "top",
        duration: 4,
      });
    } catch (err) {
      setIsLoading(false);
      notificationAPI.error({
        message: "Error",
        description: err?.response?.data?.error,
        placement: "top",
        duration: 4,
      });
    }
  };
  return (
    <Background>
      <CustomCard>
        <Title level={2} className="!font-bold">
          Forgot Password
        </Title>

        <Form onFinish={onFinish} layout="vertical">
          <Item
            label="Email"
            name="email"
            className="pt-5"
            rules={[
              {
                required: true,
                message: "Please enter your email",
              },
              {
                validator: (_, value) => {
                  if (
                    value &&
                    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                      value
                    )
                  ) {
                    return Promise.reject(new Error("The email is not valid!"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="test@test.com" size="large" />
          </Item>
          <Item>
            <CustomButton
              text={isLoading ? <Spin /> : "Reset Password"}
              type="primary"
              htmlType="submit"
              disabled={isLoading}
            />
          </Item>
        </Form>
        <CustomButton
          text={
            <div className="flex flex-row gap-3">
              <ArrowLeftOutlined className="text-[17px]" />
              <p>Back</p>
            </div>
          }
          variant="outlined"
          className="h-11 mb-3"
          onClick={() => navigate("/signin")}
        />
      </CustomCard>
    </Background>
  );
};

export default ForgotPassword;
