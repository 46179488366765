import React, { useContext } from "react";
import {
  BellOutlined,
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Button, Dropdown, Tooltip, Typography } from "antd";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import api from "../../api/axios";
import { MenuOutlined } from "@ant-design/icons";
import Notification from "../Notification";

const { Text } = Typography;

const Header = ({ setIsDrawerOpen, isMobile }) => {
  const navigate = useNavigate();
  const { logout, user } = useContext(AuthContext);

  const items = [
    {
      key: "1",
      label: (
        <Text
          onClick={async () => {
            // api.post("/logout", )
            logout();
            navigate("/signin");
          }}
        >
          Logout
        </Text>
      ),
    },
  ];

  const userLogout = async () => {
    logout();
    navigate("/signin");
  };
  return (
    <>
      {isMobile ? (
        <div className="px-4 py-2 h-20 border border-[ #0000000F] rounded-[2px] flex justify-between items-center">
          <Text className={"text-lg font-bold"}>
            {localStorage.getItem("headerTitle")}
          </Text>
          <MenuOutlined onClick={() => setIsDrawerOpen(true)} />
        </div>
      ) : (
        <div className=" px-4 py-2 h-16 bg-inherit flex justify-end items-center gap-5 md:bg-appNavyBlue ">
          <Notification />

          <Avatar icon={<UserOutlined />} className="bg-gray-300" />

          <Text className="text-white">{user?.data?.user?.name}</Text>

          <Tooltip title={"Logout"}>
            <Button
              icon={<LogoutOutlined />}
              onClick={() => userLogout()}
              className="bg-gray-300"
            />
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default Header;
