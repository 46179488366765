import React, { useContext, useEffect, useState } from "react";
import { Divider, Modal, Typography } from "antd";

import AssessmentOne from "../../assets/assessment-type-1.png";
import AssessmentTwo from "../../assets/assessment-type-2.png";
import AssessmentThree from "../../assets/assessment-type-3.png";
import CustomButton from "../CustomButton";
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";
import assessmentApi from "../../api/assessmentAxios";
import { notificationsContext } from "../../context/Notifications";
import TokenExpireModal from "../TokenExpireModal";
const { Title, Text } = Typography;

const SelectTypeModal = ({
  isTypeModalOpen,
  setIsTypeModalOpen,
  setSelectedType,
  setIsScheduleModalOpen,
}) => {
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [allStandards, setAllStandards] = useState([]);

  useEffect(() => {
    (async () => await getStandards())();
  }, []);

  const getStandards = async () => {
    try {
      const standards = await assessmentApi.get("/standard");
      setAllStandards(standards?.data?.data);
    } catch (err) {
       if (err?.response?.data?.token_expire === true) {
         setIsTokenExpiredModalOpen(true);
       } else {
         notificationAPI.error({
           message: "Error",
           description: err?.message,
           placement: "top",
           duration: 4,
         });
       }
    }
  };

  const handleCancel = () => {
    setIsTypeModalOpen(false);
  };

  const onSelectOne = () => {
    setSelectedType("NIS2 EU Directive");
    handleCancel();
    setIsScheduleModalOpen(true);
  };

  const onSelectTwo = () => {
    setSelectedType("IEC 62344-2-1");
    handleCancel();
    setIsScheduleModalOpen(true);
  };

  const onSelectThree = () => {
    setSelectedType("Custom");
    handleCancel();
    setIsScheduleModalOpen(true);
  };

  return (
    <>
      <Modal
        title={
          <div className="w-full items-center flex justify-between">
            <Title level={4}>Select Assessment Type</Title>
            <CloseCircleOutlined
              onClick={handleCancel}
              className="text-[22px] cursor-pointer"
            />
          </div>
        }
        open={isTypeModalOpen}
        // onOk={handleOk}
        closeIcon={false}
        centered
        footer={null}
        width={750}
        className="!rounded-full"
      >
        <div className="my-6">
          <Text type="secondary">
            Lorem ipsum dolor sit amet consectetur. Volutpat etiam consectetur
            dui semper.
          </Text>
          <Divider />
          <div className="flex justify-between">
            {allStandards.map((standard) => (
              <div className="flex flex-col gap-2 items-center">
                <Text>{standard?.name}</Text>
                <img src={AssessmentOne} />
                <CustomButton
                  text="Select Assessment"
                  type="primary"
                  className="h-10"
                  onClick={() => {
                    setSelectedType({ id: standard?.id, name: standard?.name });
                    handleCancel();
                    setIsScheduleModalOpen(true);
                  }}
                />
              </div>
            ))}

            <div className="flex flex-col gap-2 items-center">
              <Text>Custom Assessment</Text>
              <img src={AssessmentThree} />
              <CustomButton
                text="Select Assessment"
                type="primary"
                className="h-10"
                onClick={onSelectThree}
              />
            </div>
          </div>
        </div>
      </Modal>
      <TokenExpireModal
        isTokenExpiredModalOpen={isTokenExpiredModalOpen}
        setIsTokenExpiredModalOpen={setIsTokenExpiredModalOpen}
      />
    </>
  );
};

export default SelectTypeModal;
