import React, { useContext, useState } from "react";
import { Typography, Form, Input, Spin } from "antd";
import Background from "../components/Background";
import CustomCard from "../components/CustomCard";
import CustomButton from "../components/CustomButton";
import { useNavigate } from "react-router-dom";
import api from "../api/axios";
import { AuthContext } from "../context/AuthContext";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import { useParams, useSearchParams } from "react-router-dom";
import { notificationsContext } from "../context/Notifications";

const { Title, Text, Link } = Typography;
const ResetPassword = () => {
  const { Item } = Form;
  const { email } = useParams();
  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resendingLoading, setResendingLoading] = useState(false);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    navigate(`/signin`);
  };

  const onFinish = async (values) => {
    const data = {
      email: email,
      confirmation_code: values.code,
      new_password: values.password,
    };
    try {
      setIsLoading(true);
      const res = await api.post("/reset-password", data);
      setIsLoading(false);
      navigate(`/signin`);
      notificationAPI.success({
        message: "Success",
        description: res?.data?.message,
        placement: "top",
        duration: 4,
      });
    } catch (err) {
      setIsLoading(false);
      notificationAPI.error({
        message: "Error",
        description: err?.response?.data?.error,
        placement: "top",
        duration: 4,
      });
    }
  };
  const resendCode = async () => {
    try {
      setResendingLoading(true);
      const data = { email: email, action: "FORGOT-PASSWORD" };
      const res = await api.post("/resend-code", data);
      setResendingLoading(false);
      notificationAPI.success({
        message: "Code is resent successfully",
        placement: "top",
        duration: 4,
      });
    } catch (err) {
      setResendingLoading(false);
      notificationAPI.error({
        message: "Error",
        description: err?.response?.data?.error,
        placement: "top",
        duration: 4,
      });
    }
  };
  const validatePassword = (value) => {
    const errors = [];
    if (value.length < 8) errors.push("at least 8 characters long");
    if (!/[A-Z]/.test(value)) errors.push("1 uppercase letter");
    if (!/[a-z]/.test(value)) errors.push("1 lowercase letter");
    if (!/[0-9]/.test(value)) errors.push("1 number");
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value))
      errors.push("1 special character");

    if (errors.length > 0) {
      setPasswordError(`Your password must be ${errors.join(", ")}.`);
    } else {
      setPasswordError("");
    }
  };
  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <span style={{ color: "red" }}>*</span>}
    </>
  );
  return (
    <Background>
      <div className="mt-20 sm:mt-0">
        <CustomCard>
          <Title level={2} className=" !text-2xl sm:!font-bold ">
            Create New Password
          </Title>

          <Form
            onFinish={onFinish}
            layout="vertical"
            requiredMark={customizeRequiredMark}
          >
            <style>
              {`    input[type="number"] {
              -moz-appearance: textfield; /* For Firefox */
            }

             input[type="number"]::-webkit-inner-spin-button,
             input[type="number"]::-webkit-outer-spin-button {
             -webkit-appearance: none; /* For WebKit browsers */
             margin: 0;
            }
            ul {
              list-style-type: disc; 
              padding-left: 20px; 
              }`}
            </style>
            <div className="mb-2">
              <p style={{ color: "gray" }}>
                To protect your account, make sure your password
              </p>
              <ul style={{ color: "gray" }}>
                <li> Is longer than 7 characters</li>
                <li> Should contain numeric and alphabetic both</li>
              </ul>
            </div>
            <Item
              label="Verify Code"
              name="code"
              className="mt-3"
              rules={[
                {
                  required: true,
                  message: "Please enter your code",
                },
                {
                  pattern: /^\d{6}$/,
                  message: "Code must be a 6-digit number",
                },
              ]}
            >
              <Input type="number" placeholder="Enter code" size="large" />
            </Item>
            <Item
              label={
                <>
                  Password <span style={{ color: "red" }}>*</span>
                </>
              }
              name="password"
              rules={[
                {
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(
                        new Error("Please enter your password")
                      );
                    }
                    validatePassword(value);
                    if (passwordError) {
                      return Promise.reject(new Error(passwordError));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input.Password
                placeholder="Enter your password"
                size="large"
                onChange={(e) => validatePassword(e.target.value)}
              />
            </Item>

            <Item
              label="Confirm Password"
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Password & Confirm Password do not match")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm your password"
                size="large"
              />
            </Item>
            <Item>
              <CustomButton
                text={isLoading ? <Spin /> : "Update Password"}
                type="primary"
                htmlType="submit"
                disabled={isLoading}
              />
            </Item>
            <Item>
              <CustomButton
                text={
                  <div className="flex flex-row gap-3">
                    <ArrowLeftOutlined className="text-[17px]" />
                    <p>Back</p>
                  </div>
                }
                variant="outlined"
                onClick={handleCancel}
              />
            </Item>
            <div className="flex flex-row items-center justify-center w-full gap-1">
              <Text type="secondary">Didn’t receive the code?</Text>
              <Link
                className="!underline !text-appBlue"
                onClick={() => resendCode()}
              >
                {resendingLoading ? <Spin size="small" /> : "Resend code"}
              </Link>
            </div>
          </Form>
        </CustomCard>
      </div>
    </Background>
  );
};

export default ResetPassword;
