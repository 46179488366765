import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: "notifications-91394",
    storageBucket: "notifications-91394.firebasestorage.app",
    messagingSenderId: "151895364121",
    appId: "1:151895364121:web:72f23c6649e640f4bf5088"
  };

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
