import React from "react";
import { Badge, Space } from "antd";

const statuses = [
  { status: "Pending Assignment", label: "Pending Assignment", color: "red" },
  { status: "In Progress", label: "In Progress", color: "green" },
  { status: "Resolved", label: "Resolved", color: "blue" },
  { status: "Pending Review", label: "Pending Review", color: "purple" },
  { status: "To Do", label: "To Do", color: "Yellow" },
  { status: "PENDING", label: "PENDING", color: "purple" },
  { status: "In Review", label: "In Review", color: "purple" },
];

const CustomBadge = ({ data }) => {
  return (
    <Space direction="vertical">
      {data.map((item, index) => {
        if (!item.status) {
          return (
            <span key={index} className="text-gray-500">
              -
            </span>
          );
        }
        const status = statuses.find((s) => s.status === item.status);
        return (
          status && (
            <Badge key={index} color={status.color} text={status.label} />
          )
        );
      })}
    </Space>
  );
};

export default CustomBadge;
