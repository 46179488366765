import React from "react";

const DashboardBackground = ({ children, bg }) => {
  return (
    <div
      className={
        bg
          ? "bg-transparent sm:bg-white sm:h-[94vh]"
          : "bg-transparent sm:bg-[#F4F7FF] sm:h-[94vh] "
      }
    >
      {children}
    </div>
  );
};

export default DashboardBackground;
