import React, { useEffect, useState, useContext } from "react";
import { Table, Typography, Spin, Tag, Tooltip, DatePicker } from "antd";
import DashboardBackground from "../components/Dashboard/DashboardBackground";
import CustomSearch from "../components/CustomSearch";
import CustomButton from "../components/CustomButton";
import { notificationsContext } from "../context/Notifications";
import { useNavigate } from "react-router-dom";
import TokenExpireModal from "../components/TokenExpireModal";
import AssignModal from "../components/Remediation/AssignModal";
import EvidenceModal from "../components/Remediation/EvidenceModal";
import assessmentApi from "../api/assessmentAxios";
import CustomBadge from "../components/CustomBadge";
import { GLOBAL_DATE_FORMATE } from "../utils/constants";
import dayjs from "dayjs";
import moment from "moment";
import DueDateUpdateConfirmationModal from "../components/Assessment/DueDateUpdateConfirmationModal";
import { WarningOutlined } from "@ant-design/icons";

const { Title } = Typography;

const Remediation = () => {
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isEvidenceModalOpen, setIsEvidenceModalOpen] = useState(false);
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [searchText, setSearchText] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);
  const [data, setData] = useState([]);
  const [remediationId, setRemediationId] = useState(null);
  const [isDueDateUpdateModal, setIsDueDateUpdateModal] = useState(false);
  const [assessmentIdForUpdatedDueDate, setAssessmentIdForUpdatedDueDate] =
    useState(null);
  const [assessmentNameForUpdatedDueDate, setAssessmentNameForUpdatedDueDate] =
    useState("");
  const [
    assessmentPerviousDueForUpdatedDueDate,
    setAssessmentPerviousDueForUpdatedDueDate,
  ] = useState("");
  const [
    assessmentUpdatedDueForUpdatedDueDate,
    setAssessmentUpdatedDueForUpdatedDueDate,
  ] = useState("");

  // Update the date in the dataSource
  const handleDateChange = (date, dateString, record) => {
    setAssessmentIdForUpdatedDueDate(record?.id);

    let previousDate = new Date(record?.due_date);
    previousDate = previousDate.toLocaleDateString("en-GB");
    setAssessmentPerviousDueForUpdatedDueDate(previousDate);

    setAssessmentUpdatedDueForUpdatedDueDate(dateString);

    setIsDueDateUpdateModal(true);
  };
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const storedUser = JSON.parse(localStorage.getItem("user"));
  const currentUserEmail = storedUser?.data?.user?.email;
  const tenantId = storedUser?.data?.user?.tenant_id;
  useEffect(() => {
    (async () => await fetchData())();
  }, [refreshTable, searchText]);

  const fetchData = async (
    currentPage = 1,
    pageSize = 10,
    sort_by,
    sort_order
  ) => {
    try {
      setIsLoading(true);
      const params = {
        page_number: currentPage,
        page_limit: pageSize,
        sort_by: sort_by,
        sort_order: sort_order,
        tenant_id: tenantId,
      };

      if (searchText.length > 0) params.search_text = searchText;

      const remediations = await assessmentApi.get("/remediation", { params });
      setData(remediations?.data?.data?.rows);

      setTableParams({
        pagination: {
          current: currentPage,
          pageSize,
          total: remediations?.data?.data?.count,
        },
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      notificationAPI.error({
        message: "Error",
        description: err?.message,
        placement: "top",
        duration: 4,
      });
    }
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    const sort_by = sorter.field; // Get the field to sort by
    const sortOrder = sorter.order;
    const sort_order =
      sortOrder === "ascend"
        ? "asc"
        : sortOrder === "descend"
          ? "desc"
          : undefined;
    setTableParams({
      pagination,
    });
    await fetchData(
      pagination.current,
      pagination.pageSize,
      sort_by,
      sort_order
    );
  };

  const isPastDue = (dueDate) => {
    const today = moment();
    const due = moment(dueDate);
    return due.isBefore(today, "day");
  };
  const columns = [
    {
      title: "Requirement",
      dataIndex: "questionnaire",
      key: "questionnaire",
      render: (text) => {
        const question = text?.question_statement || "";
        const truncatedQuestion =
          question.length > 100 ? `${question.slice(0, 40)}...` : question;
        return (
          <Tooltip title={question}>
            <span className="capitalize cursor-pointer">
              {truncatedQuestion}
            </span>
          </Tooltip>
        );
      },
    },

    {
      title: "Missing Control",
      dataIndex: "missing_control",
      key: "missing_control",
      sorter: true,
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      sorter: true,
      render: (_, { status }) => {
        return <CustomBadge data={[{ status }]} />;
      },
    },
    {
      title: "Assigned Users",
      dataIndex: "assign_to_attributes",
      key: "assign",
      render: (_, record) => {

        // Check if 'assigned_to' is a non-empty string
        const assignedTo = record?.assigned_to;
        if (typeof assignedTo === "string" && assignedTo.trim() !== "") {
          return <span className="capitalize">{assignedTo}</span>;
        }

        // Fallback to 'assign_to_attributes' if 'assigned_to' is null or empty
        const assignToAttributes = record?.assign_to_attributes;
        if (Array.isArray(assignToAttributes) && assignToAttributes.length > 0) {
          const assignedNames = assignToAttributes
            .map((item) => item.assigned_to_name) // Extract names from objects
            .join(", "); // Join them with commas
          return <span className="capitalize">{assignedNames}</span>;
        }

        // Default case: Show dash centered
        return <span className="flex justify-center items-center">-</span>;
      },
    },

    {
      title: "Remediation Severity",
      dataIndex: "remediation_severity",
      key: "remediation_severity",
      sorter: true,
      render: (text) => <span className="capitalize ">{text}</span>,
      align: "center",
    },

    {
      title: "Assigned Date",
      dataIndex: "assigned_date",
      key: "assigned_date",
      render: (assigned_date) => {
        if (assigned_date) {
          const date = new Date(assigned_date);
          const formattedDate = date.toLocaleDateString("en-GB");
          return formattedDate;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (text, record) => {
        const isPickerDisabled =
          record?.status === "Resolved" || isPastDue(record?.due_date);
        const date = new Date(record?.due_date);
        const formattedDate = date.toLocaleDateString("en-GB");
        if (isPickerDisabled) {
          return <span>{formattedDate}</span>;
        }
        if (record?.due_date) {
          return (
            <DatePicker
              defaultValue={dayjs(formattedDate, GLOBAL_DATE_FORMATE)}
              onChange={(date, dateString) =>
                handleDateChange(date, dateString, record)
              }
              format={GLOBAL_DATE_FORMATE}
              allowClear={false}
              disabled={isPickerDisabled}
              style={{
                width: "120px",
                border: "none",
                cursor: isPickerDisabled ? "not-allowed" : "pointer",
              }}
              disabledDate={(current) =>
                current.isBefore(moment().subtract(1, "day"))
              }
            />
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "Actions",
      key: "action",

      render: (_, record) => {
        return record?.status === "Pending Assignment" ? (
          <a
            className="capitalize text-cyan-500"
            onClick={() => {
              setIsAssignModalOpen(true);
              setRemediationId(record.id);
            }}
          >
            Assign
          </a>
        ) : (
          <div className="w-full flex justify-center">
            <div className="w-18">
              <CustomButton
                text={
                  record?.status === "In Progress"
                    ? "View"
                    : record?.status === "Resolved"
                      ? "Resolved"
                      : record?.status === "In Review" &&
                        record.assign_from_attributes.assigned_by_email ==
                        currentUserEmail
                        ? "Review"
                        : "View"
                }
                disabled={
                  isPastDue(record?.due_date) || record?.status === "Resolved"
                }
                variant="outlined"
                className="h-7"
                onClick={() =>
                  navigate(
                    `/dashboard/remediation/remediation-detail/${record.id}`
                  )
                }
              />
            </div>
          </div>
        );
      },
      align: "center",
    },
  ];

  const updateDueDateForAssessment = () => {
    console.log("update date handler");
  };
  const handleCancelDate = () => {
    setIsDueDateUpdateModal(false);
  };

  return (
    <DashboardBackground>
      <div className="h-16 bg-white px-5 flex justify-between items-center">
        <CustomSearch
          setSearchText={setSearchText}
          className={"w-full lg:w-auto"}
        />
      </div>
      <div className="h-[80vh] md:m-5 bg-white px-5 pt-5 overflow-scroll overflow-x-hidden">
        <Title level={3} className="hidden md:block">
          Remediation
        </Title>
        <div className="mt-2 overflow-x-scroll lg:mt-6 lg:overflow-x-hidden">
          <div className="w-[78vw] md:w-[60vw] lg:w-auto">
            <Table
              columns={columns}
              dataSource={data}
              loading={isLoading}
              pagination={{
                ...tableParams.pagination,
                position: ["bottomCenter"],
                showSizeChanger: true,
              }}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
      <TokenExpireModal
        isTokenExpiredModalOpen={isTokenExpiredModalOpen}
        setIsTokenExpiredModalOpen={setIsTokenExpiredModalOpen}
      />
      <AssignModal
        isAssignModalOpen={isAssignModalOpen}
        setIsAssignModalOpen={setIsAssignModalOpen}
        setRefreshTable={setRefreshTable}
        remediationId={remediationId}
      />
      <EvidenceModal
        isEvidenceModalOpen={isEvidenceModalOpen}
        setIsEvidenceModalOpen={setIsEvidenceModalOpen}
        setRefreshTable={setRefreshTable}
      />
      <DueDateUpdateConfirmationModal
        isModalOpen={isDueDateUpdateModal}
        setIsModalOpen={setIsDueDateUpdateModal}
        title="Are you sure to update the Due Date?"
        icon={<WarningOutlined className="text-yellow-500 text-2xl" />}
        bodyText={`You updating the due date  from ${assessmentPerviousDueForUpdatedDueDate} to ${assessmentUpdatedDueForUpdatedDueDate}`}
        buttons={[
          {
            text: "Submit",
            type: "primary",
            onClick: () => updateDueDateForAssessment(),
          },
          {
            text: "Cancel",
            type: "primary",
            onClick: () => handleCancelDate(),
          },
        ]}
      />
    </DashboardBackground>
  );
};
export default Remediation;
