import React, { useState, useContext, useEffect } from "react";
import {
  Divider,
  Form,
  Modal,
  Typography,
  Spin,
  Upload,
  Button,
  message,
} from "antd";
import {
  InboxOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import CustomButton from "../components/CustomButton";
import "react-phone-number-input/style.css";
import TokenExpireModal from "../components/TokenExpireModal";
import { notificationsContext } from "../context/Notifications";

const { Dragger } = Upload;
const { Title } = Typography;

const props = {
  name: "file",
  multiple: false,
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
  defaultFileList: [],
  showUploadList: {
    extra: ({ size = 0 }) => (
      <span
        style={{
          color: "#cccccc",
        }}
      >
        ({(size / 1024 / 1024).toFixed(2)}MB)
      </span>
    ),
    showDownloadIcon: true,
    downloadIcon: "Download",
    showRemoveIcon: true,
    removeIcon: (
      <DeleteOutlined
        onClick={(e) => console.log(e, "custom removeIcon event")}
      />
    ),
  },
};

const UploadModal = ({ setIsUploadModalOpen, isUploadModalOpen }) => {
  const [isUploadLoading, setIsInviteUserLoading] = useState(false);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [value, setValue] = useState("");
  const [form] = Form.useForm();
  const { Item } = Form;

  const handleCancel = () => {
    form.resetFields();
    setIsUploadModalOpen(false);
  };

  const onFinish = async (values) => {
    console.log(values);
  };

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <span style={{ color: "red" }}>*</span>}
    </>
  );

  return (
    <>
      <Modal
        title={
          <div className="w-full items-start flex justify-between">
            <Title level={4}>Upload Requirements File</Title>
            <CloseCircleOutlined
              onClick={handleCancel}
              className="text-[22px] cursor-pointer"
            />
          </div>
        }
        open={isUploadModalOpen}
        closeIcon={false}
        centered
        footer={null}
        width={500}
      >
        <Divider className="m-0" />
        <div style={{ maxheight: "90vh" }}>
          <Form
            className="mt-5"
            form={form}
            onFinish={onFinish}
            layout="vertical"
            requiredMark={customizeRequiredMark}
          >
            <style>
              {`
                  .custom-spin .ant-spin-dot {
              color: white;
            }
          `}
            </style>
            <Item
              name="file"
              rules={[{ required: true, message: "Please enter user name" }]}
            >
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
            </Item>
            <Divider className="m-0" />
            <Item className="flex justify-end items-center">
              <Button type="primary" htmlType="submit" className="h-11 mt-4">
                {isUploadLoading ? (
                  <Spin className="custom-spin" />
                ) : (
                  "Upload Requirements"
                )}
              </Button>
            </Item>
          </Form>
        </div>
      </Modal>
      <TokenExpireModal
        isTokenExpiredModalOpen={isTokenExpiredModalOpen}
        setIsTokenExpiredModalOpen={setIsTokenExpiredModalOpen}
      />
    </>
  );
};
export default UploadModal;
