import { Card, Collapse, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useSearchParams } from "react-router-dom";

const AssessmentDescription = ({ assessmentDetails, assets }) => {
  const { Title } = Typography;
  const [searchParams] = useSearchParams();
  const standardName = searchParams.get("standard");

  return (
    <>
      <div className="w-full md:w-auto">
        <Title level={4} className="!m-0 ">
          {standardName} - {assessmentDetails?.name ?? "-"}
        </Title>
      </div>
      <Card style={{ borderWidth: "3px", marginTop: "10px" }}>
        <div className="flex w-full flex-col lg:flex-row lg:justify-between">
          <div className="w-auto lg:w-[47%]">
            <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
              <div className="font-semibold">Assessment Type:</div>
              <div className="flex flex-col">
                {assessmentDetails?.type ?? "-"}
              </div>
            </div>
            <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
              <div className="font-semibold">Industry:</div>
              <div className="flex flex-col">
                {" "}
                {assessmentDetails?.industry ?? "-"}
              </div>
            </div>
            <div className="flex border-b border-gray-100 justify-between align-center h-7 mb-3">
              <div className="font-semibold">Location:</div>
              <div> {assessmentDetails?.location ?? "-"}</div>
            </div>
          </div>
          <div className="w-auto lg:w-[47%]">
            <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
              <div className="font-semibold">Assign To:</div>
              <div className="flex flex-col">
                {assessmentDetails?.assigned_to ?? "-"}
              </div>
            </div>
            <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
              <div className="font-semibold">Primary Contact:</div>
              <div className="flex flex-col">
                {" "}
                {assessmentDetails?.primary_contact ?? "-"}
              </div>
            </div>
            <div className="flex border-b border-gray-100 justify-between align-center h-auto ">
              <div className="font-semibold">Due Date</div>
              <div className="flex flex-col">
                {moment(assessmentDetails?.due_date).format("MM/DD/YYYY") ??
                  "-"}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-col flex border-b border-gray-100 md:flex-row md:justify-between align-center h-auto">
          <div className="font-semibold">Description:</div>
          <div className="flex flex-col text-justify">
            {assessmentDetails?.description ?? "-"}
          </div>
        </div>
      </Card>
      <div className="mt-3">
        <Title level={4} className="mt-2">
          Assets
        </Title>
        {assets?.length > 0 ? (
          <Collapse defaultActiveKey={["1"]} accordion>
            {assets.map((asset) => (
              <Collapse.Panel header={asset?.asset_name} key={asset?.nozomi_id}>
                <Card style={{ borderWidth: "3px", marginTop: "10px" }}>
                  <div className="flex w-full flex-col lg:flex-row lg:justify-between">
                    <div className="w-auto lg:w-[47%]">
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">Asset Name:</div>
                        <div className="flex flex-col">
                          {asset?.asset_name ?? "-"}
                        </div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">Type:</div>
                        <div className="flex flex-col">
                          {" "}
                          {asset?.type ?? "-"}
                        </div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-7 mb-3">
                        <div className="font-semibold">Vendor:</div>
                        <div> {asset?.vendor ?? "-"}</div>
                      </div>
                    </div>
                    <div className="w-auto lg:w-[47%]">
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">IP:</div>
                        <div className="flex flex-col">{asset?.ip ?? "-"}</div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto mb-3">
                        <div className="font-semibold">Mac Address:</div>
                        <div className="flex flex-col">
                          {asset?.macAddress ?? "-"}
                        </div>
                      </div>
                      <div className="flex border-b border-gray-100 justify-between align-center h-auto ">
                        <div className="font-semibold">Mac Vendor:</div>
                        <div className="flex flex-col">
                          {asset?.macVendor ?? "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex border-b border-gray-100 justify-between align-center h-auto">
                    <div className="font-semibold">Address:</div>
                    <div className="flex flex-col">
                      {assessmentDetails?.address ?? "-"}
                    </div>
                  </div>
                </Card>
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : (
          <div className="flex justify-center items-center mt-5">
            No Assets available.
          </div>
        )}
      </div>
    </>
  );
};

export default AssessmentDescription;
