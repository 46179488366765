import React, { useEffect, useState, useContext } from "react";
import { Table, Typography, Spin } from "antd";
import DashboardBackground from "../components/Dashboard/DashboardBackground";
import CustomSearch from "../components/CustomSearch";
import CustomButton from "../components/CustomButton";
import AddAssetModal from "../components/Asset/AddAssetModal";
import ViewAssetDetailModal from "../components/Asset/ViewAssetDetailModal";
import { notificationsContext } from "../context/Notifications";
import { useNavigate } from "react-router-dom";
import newAsset from "../api/assetAxios";
import TokenExpireModal from "../components/TokenExpireModal";
const { Title } = Typography;
const Asset = () => {
  const [isAddAssetModalOpen, setIsAddAssetModalOpen] = useState(false);
  const [isAssetDetailModalOpen, setIsAssetDetailModalOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState();
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);
  const navigate = useNavigate();

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    (async () => await fetchData())();
  }, [refreshTable, searchText]);

  const fetchData = async (currentPage = 1, pageSize = 10) => {
    try {
      setIsLoading(true);
      const params = {
        page_number: currentPage,
        page_limit: pageSize,
      };

      if (searchText.length > 0) params.search_text = searchText;
      const res = await newAsset.get(`/asset`, { params });
      setIsLoading(false);
      setData(res?.data?.data?.rows);
      setTableParams({
        pagination: {
          current: currentPage,
          pageSize,
          total: res?.data?.data?.count,
        },
      });
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };
  const handleTableChange = async (pagination) => {
    setTableParams({
      pagination,
    });
    await fetchData(pagination.current, pagination.pageSize);
  };

  const showAssetDetailModal = (asset) => {
    setSelectedAsset(asset);
    setIsAssetDetailModalOpen(true);
  };

  const columns = [
    {
      title: "Asset Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Address",
      dataIndex: "ip",
      key: "ip",
      render: (text) => (
        <span className="capitalize">
          {Array.isArray(text) ? text.join(", ") : ""}
        </span>
      ),
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
    },
    {
      title: "Location",
      dataIndex: "appliance_sites",
      key: "appliance_sites",
      render: (text) => (
        <span className="capitalize">
          {Array.isArray(text) ? text.join(", ") : ""}
        </span>
      ),
    },
    // {
    //   title: "Attachment",
    //   dataIndex: "attachment",
    //   key: "attachment",
    // },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <div className="w-full flex justify-center">
          <div className="w-14">
            <CustomButton
              text="View"
              variant="outlined"
              className="h-7"
              onClick={() => navigate(`/dashboard/assets/${record?.id}`)}
            />
          </div>
        </div>
      ),
      align: "center",
    },
  ];
  const showAddAssetModal = () => {
    setIsAddAssetModalOpen(true);
  };

  return (
    <DashboardBackground>
      <div className="flex flex-col gap-4 mt-6 lg:mt-0   lg:h-16 bg-white px-5  lg:flex-row lg:justify-between items-center">
        <CustomSearch
          setSearchText={setSearchText}
          className={"w-full lg:w-auto"}
        />
        <div className="w-full lg:w-48 ">
          <CustomButton
            text="Add Asset"
            type="primary"
            className="h-12 lg:h-9"
            onClick={showAddAssetModal}
          />
          <AddAssetModal
            isAddAssetModalOpen={isAddAssetModalOpen}
            setIsAddAssetModalOpen={setIsAddAssetModalOpen}
            setData={setData}
            data={data}
          />
          <ViewAssetDetailModal
            isAssetDetailModalOpen={isAssetDetailModalOpen}
            setIsAssetDetailModalOpen={setIsAssetDetailModalOpen}
            selectedAsset={selectedAsset}
          />
          <TokenExpireModal
            isTokenExpiredModalOpen={isTokenExpiredModalOpen}
            setIsTokenExpiredModalOpen={setIsTokenExpiredModalOpen}
          />
        </div>
      </div>
      <div className="h-[80vh] m-5 bg-white px-5 pt-5 overflow-scroll overflow-x-hidden">
        <Title level={3} className="hidden md:block">
          Assets
        </Title>
        {isLoading ? (
          <Spin className="flex justify-center items-center" />
        ) : (
          <div className="overflow-x-scroll  lg:overflow-x-hidden">
            <div className="w-[75vw] md:w-[55vw] lg:w-auto">
              <Table
                columns={columns}
                dataSource={data}
                loading={isLoading}
                pagination={{
                  ...tableParams.pagination,
                  position: ["bottomCenter"],
                  showSizeChanger: true,
                }}
                onChange={handleTableChange}
              />
            </div>
          </div>
        )}
      </div>
    </DashboardBackground>
  );
};
export default Asset;
